import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { useTranslation } from 'react-i18next';

import { ShowSetting, ShowSettingMinMax } from '../FormWidgets/ShowSliderSetting';
import { RegTypeSelect, RegTypeHumidifierSelect } from '../FormWidgets/Select.js';
import Desc, { GetHelp, InfoButton } from '../FormWidgets/Description';
import { HandleAction } from '../Utils/utils.js';
import { LoadSettingsDialog } from '../Utils/load_settings';
import { SettingGroupGrid, ValueBox } from '../FormWidgets/SettingGroup';

import {
  settingControls,
  useSavedSettings
} from './settingFunctions';
import { SettingActions } from '../components';

import useStateManager from '../StateManager.js';

export default function AdvancedSettings({ contType, ...other }) {
  let { t } = useTranslation();
  let { id, tab_id } = useParams();
  
  const stateManager = useStateManager();
  const controller = stateManager.getControllerById (id);
  const [values, setValues] = useState (controller.settings[tab_id]);
  const controls = settingControls (controller, tab_id, stateManager.saveSettings, values, setValues);
  const { openLoadSettingsDialog, closeLoadSettingsDialog } = useSavedSettings (controller, controls);
  
  const callAction = (type, value) => {
    if (controller.connection.status !== 'bad') {
		HandleAction (stateManager.wsManager, controller, type, value);
	}
  };
  
  // Interaval too check wether data should be refreshed.
  const help = GetHelp('reset_event_dates', '', contType);

  return (
    <React.Fragment>
      <LoadSettingsDialog
        open={openLoadSettingsDialog}
        handleClose={closeLoadSettingsDialog}
        controls={controls}
      />

      <SettingActions
        onReset={controls.resetSettings}
        onSave={controls.saveSettings}
        
      />

      <SettingGroupGrid label={t('advanced_ventilation')}>
        <ShowSetting
          id="fan_ac_exhaust_turnoff"
          cur_value={values.fan_ac_exhaust_turnoff}
          controller_value={controller.settings.advanced.fan_ac_exhaust_turnoff}
          controls={controls}
        />
        <ShowSetting
          id="fan_ac_intake_turnoff"
          cur_value={values.fan_ac_intake_turnoff}
          controller_value={controller.settings.advanced.fan_ac_intake_turnoff}
          controls={controls}
        />
      </SettingGroupGrid>

      <SettingGroupGrid label={t('co2')}>
        <ShowSetting
          id="co2_min_dose_ms"
          cur_value={values.co2_min_dose_ms}
          controller_value={controller.settings.advanced.co2_min_dose_ms}
          controls={controls}
        />
        <ShowSetting
          id="hysteresis_co2"
          cur_value={values.hysteresis_co2}
          controller_value={controller.settings.advanced.hysteresis_co2}
          controls={controls}
        />
      </SettingGroupGrid>

      <SettingGroupGrid label={t('climate')}>
        <ShowSetting
          id="leaf_temp_diff"
          cur_value={values.leaf_temp_diff}
          controller_value={controller.settings.advanced.leaf_temp_diff}
          controls={controls}
          type="float"
        />
        {contType === 5055 && (
          <ShowSettingMinMax
            id="rh_max_with_vpd"
            show="vpd_rh_allowed"
            cur_value_max={values.rh_max_with_vpd}
            controller_value_max={controller.settings.advanced.rh_max_with_vpd}
            cur_value_min={values.rh_min_with_vpd}
            controller_value_min={controller.settings.advanced.rh_min_with_vpd}
            min={5}
            max={95}
            off_at={-1}
            controls={controls}
            type="fan_min_max"
          />
        )}

        {contType === 5055 && (
          <ShowSetting
            id="intake_sens_min_diff_rh"
            show="intake_min_diff_rh"
            cur_value={values.intake_sens_min_diff_rh}
            controller_value={controller.settings.advanced.intake_sens_min_diff_rh}
            controls={controls}
            off_at={0}
            type="float"
          />
        )}
        {contType === 5055 && (
          <ShowSetting
            id="intake_sens_min_diff_temp"
            show="intake_min_diff_temp"
            cur_value={values.intake_sens_min_diff_temp}
            controller_value={controller.settings.advanced.intake_sens_min_diff_temp}
            controls={controls}
            off_at={0}
            type="float"
          />
        )}
        {contType === 5055 && (
          <ShowSetting
            id="intake_sens_retry_wait"
            show="intake_retry_wait"
            cur_value={values.intake_sens_retry_wait}
            controller_value={controller.settings.advanced.intake_sens_retry_wait}
            controls={controls}
            off_at={0}
          />
        )}
        {contType === 5055 && (
          <ShowSetting
            id="intake_sens_retry_duration"
            show="intake_retry_durationt"
            cur_value={values.intake_sens_retry_duration}
            controller_value={controller.settings.advanced.intake_sens_retry_duration}
            controls={controls}
          />
        )}
        <ShowSetting
          id="reg_kd_temp_air"
          cur_value={values.reg_kd_temp_air}
          controller_value={controller.settings.advanced.reg_kd_temp_air}
          controls={controls}
        />
        <ShowSetting
          id="interval_ramp_temp_sp"
          cur_value={values.interval_ramp_temp_sp}
          controller_value={controller.settings.advanced.interval_ramp_temp_sp}
          controls={controls}
          cont_type={contType}
        />
      </SettingGroupGrid>

      <SettingGroupGrid label={t('humidifier')}>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="reg_type_humidifier" />
          </Typography>
          <RegTypeHumidifierSelect
            value={values.reg_type_humidifier}
            name="reg_type_humidifier"
            onChange={(event, value) => controls.setValue ('reg_type_humidifier', event.target.value)}
          />
        </ValueBox>
        <ShowSetting
          id="hysteresis_humidifier"
          cur_value={values.hysteresis_humidifier}
          controller_value={controller.settings.advanced.hysteresis_humidifier}
          controls={controls}
          type="float"
        />
        <ShowSetting
          id="humidifier_kd"
          cur_value={values.humidifier_kd}
          controller_value={controller.settings.advanced.humidifier_kd}
          controls={controls}
        />
        <ShowSetting
          id="offset_sp_humidifier"
          cur_value={values.offset_sp_humidifier}
          controller_value={controller.settings.advanced.offset_sp_humidifier}
          controls={controls}
          type="float"
        />
      </SettingGroupGrid>

      <SettingGroupGrid label={t('dehumidifier')}>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="reg_type_dehumidifier" />
          </Typography>
          <RegTypeSelect
            value={values.reg_type_dehumidifier}
            name="reg_type_dehumidifier"
            onChange={(event, value) => controls.setValue ('reg_type_dehumidifier', event.target.value)}
          />
        </ValueBox>
        <ShowSetting
          id="hysteresis_dehumidifier"
          cur_value={values.hysteresis_dehumidifier}
          controller_value={controller.settings.advanced.hysteresis_dehumidifier}
          controls={controls}
          type="float"
        />
        <ShowSetting
          id="offset_sp_dehumidifier"
          cur_value={values.offset_sp_dehumidifier}
          controller_value={controller.settings.advanced.offset_sp_dehumidifier}
          controls={controls}
        />
      </SettingGroupGrid>

      <SettingGroupGrid label={t('dehumidify_exhaust_adv_hl')}>
        <ShowSetting
          id="dehumid_exhaust_threshold"
          cur_value={values.dehumid_exhaust_threshold}
          controller_value={controller.settings.advanced.dehumid_exhaust_threshold}
          controls={controls}
          type="float"
        />
        <ShowSetting
          id="dehumid_exhaust_temp_untercut_max_allowed"
          cur_value={values.dehumid_exhaust_temp_untercut_max_allowed}
          controller_value={controller.settings.advanced.dehumid_exhaust_temp_untercut_max_allowed}
          controls={controls}
        />
      </SettingGroupGrid>

      <SettingGroupGrid label={t('dim_lights_adv_hl')}>
        <ShowSetting
          id="lights_dim_over_temp_hyst"
          cur_value={values.lights_dim_over_temp_hyst}
          controller_value={controller.settings.advanced.lights_dim_over_temp_hyst}
          controls={controls}
          type="float"
        />
        <ShowSetting
          id="lights_dim_over_temp_delay_sec"
          cur_value={values.lights_dim_over_temp_delay_sec}
          controller_value={controller.settings.advanced.lights_dim_over_temp_delay_sec}
          controls={controls}
        />
      </SettingGroupGrid>

      {contType === 5055 && (
        <SettingGroupGrid label={t('hysteresis_heater')}>
          <ShowSetting
            id="hysteresis_heater"
            cur_value={values.hysteresis_heater}
            controller_value={controller.settings.advanced.hysteresis_heater}
            controls={controls}
            type="float"
          />
        </SettingGroupGrid>
      )}

      <SettingGroupGrid label={t('hysteresis_chiller')}>
        <ShowSetting
          id="hysteresis_chiller_low"
          cur_value={values.hysteresis_chiller_low}
          controller_value={controller.settings.advanced.hysteresis_chiller_low}
          controls={controls}
          type="float"
        />
        <ShowSetting
          id="hysteresis_chiller_high"
          cur_value={values.hysteresis_chiller_high}
          controller_value={controller.settings.advanced.hysteresis_chiller_high}
          controls={controls}
          type="float"
        />
      </SettingGroupGrid>

      <SettingGroupGrid label={t('neg_pressure')}>
        <ShowSetting
          id="reg_kd_pressure"
          cur_value={values.reg_kd_pressure}
          controller_value={controller.settings.advanced.reg_kd_pressure}
          controls={controls}
        />
      </SettingGroupGrid>

      <SettingGroupGrid label={t('timing')}>
        <div>
          <Button
            color="primary"
            onClick={() => callAction('action_reset_dates_event')}>
            {t('reset_event_dates')}
          </Button>
          <InfoButton content={help.content} img={help.img} />
        </div>
      </SettingGroupGrid>

      <SettingActions
        onReset={controls.resetSettings}
        onSave={controls.saveSettings}
        
      />
    </React.Fragment>
  );
}
