import React from 'react';
import Menu from '@material-ui/core/Menu';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import { useAuth } from '../Hooks/authHook';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function UserMenu(props) {
  const auth = useAuth();
  let { t } = useTranslation();
  const history = useHistory();

  let logout = () => {
    auth.signout(() => {
      if (process.env.REACT_APP_STATIC_USING_APP === 'true') {
        console.log('User logged out, redirecting to >>setup<<');
        history.push('/setup');
      } else {
        console.log('User logged out, redirecting to >>login<<');
        history.push('/login');
      }
    });
  };

  return (
    <Menu
      anchorEl={props.anchor}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id="user-menu"
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={props.open}
      onClose={props.onClose}
      style={{ zIndex: 9999999 }}
    >
      {auth.user
        ? [
            <MenuItem component={Link} key="link_account" to="/account" onClick={props.onClose}>
              {t('my_account')}
            </MenuItem>,
            <MenuItem component={Link} key="link_logout" to="/" onClick={logout}>
              {t('log_out')}
            </MenuItem>
          ]
        : [
            <MenuItem component={Link} key="link_register" to="/register" onClick={props.onClose}>
              {t('register')}
            </MenuItem>,
            <MenuItem component={Link} key="link_login" to="/login">
              {t('log_in')}
            </MenuItem>
          ]}
    </Menu>
  );
}
