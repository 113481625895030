import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, TimePicker, KeyboardDatePicker } from '@material-ui/pickers';

export function DatePicker({ value, ...others }) {
  const date = new Date(value + 'T00:00:00');

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="yyyy-MM-dd"
        margin="normal"
        value={date}
        {...others}
      />
    </MuiPickersUtilsProvider>
  );
}

export function MyTimePicker({ value, seconds, ...others }) {
  const date = new Date('1995-07-21T' + value);
  const format = seconds ? 'HH:mm:ss' : 'HH:mm';
  const views = seconds ? ['hours', 'minutes', 'seconds'] : ['hours', 'minutes'];

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <TimePicker
        ampm={false}
        margin="normal"
        value={date}
        views={views}
        format={format}
        {...others}
      />
    </MuiPickersUtilsProvider>
  );
}
