import React from 'react';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAddress } from '../Hooks/addressHook';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

export default function SetupPage() {
  let { t } = useTranslation();
  const address = useAddress();
  const history = useHistory();

  const [open_ip_dialog, setOpenIpDialog] = React.useState(false);
  const useLocalMode = () => {
    address.setLocalMode(true);
    setOpenIpDialog(true);
  };

  const useServerMode = () => {
    address.setLocalMode(true);
    // Use addressed defined as default
    address.setupAddress(
      process.env.REACT_APP_STATIC_DEFAULT_WEBSOCKET_ADDRESS_SRV,
      process.env.REACT_APP_STATIC_DEFAULT_API_ADDRESS_SRV
    );
    history.push('/login');
  };

  return (
    <Container maxWidth="sm">
      <Paper mt={4} component={Box}>
        <SetIPAddressDialog open={open_ip_dialog} handleClose={() => setOpenIpDialog(false)} />
        <Box mt={2} display="flex">
          <Button onClick={useLocalMode}>{t('local_mode')}</Button>
          <Button onClick={useServerMode}>{t('server_mode')}</Button>
        </Box>
      </Paper>
    </Container>
  );
}

function SetIPAddressDialog({ open, handleClose }) {
  let { t } = useTranslation();
  const address = useAddress();
  const history = useHistory();
  const [ipAddress, SetIPAddress] = React.useState(false);

  const validateForm = () => {
    if (ipAddress !== '') {
      address.setupAddress(
        'ws:' + ipAddress + ':' + process.env.REACT_APP_STATIC_DEFAULT_LOCAL_WEBSOCKET_PORT , 
        'http://' + ipAddress + ':' + process.env.REACT_APP_STATIC_DEFAULT_LOCAL_API_PORT,
      );
      history.push('/login');
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('set-ip-address')}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="serial"
          label={t('ip-address')}
          type="text"
          fullWidth
          onChange={(event) => SetIPAddress(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={validateForm} color="primary">
          {t('accept')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
