import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import { ShowSetting } from '../FormWidgets/ShowSliderSetting';
import { SettingGroupGrid, ValueBox } from '../FormWidgets/SettingGroup';
import { LoadSettingsDialog } from '../Utils/load_settings';
import { EnableDisableSelect } from '../FormWidgets/Select.js';
import Desc from '../FormWidgets/Description';

import useStateManager from '../StateManager.js';

import {
  settingControls,
  useSavedSettings,
} from './settingFunctions';
import { SettingActions } from '../components';

export default function LightsSettings({ ...other }) {
  let { t } = useTranslation();
  let { id, tab_id } = useParams();

  const stateManager = useStateManager();
  const controller = stateManager.getControllerById (id);
  const [values, setValues] = useState (controller.settings[tab_id]);
  const controls = settingControls (controller, tab_id, stateManager.saveSettings, values, setValues);
  const { openLoadSettingsDialog, closeLoadSettingsDialog } = useSavedSettings (controller, controls);

  return (
    <React.Fragment>
      <LoadSettingsDialog
        open={openLoadSettingsDialog}
        handleClose={closeLoadSettingsDialog}
        controls={controls}
      />

      <SettingActions
        onReset={controls.resetSettings}
        onSave={controls.saveSettings}
        
      />

      <SettingGroupGrid label={t('lights')}>
        <ShowSetting
          id="lights_max"
          cur_value={values.lights_max}
          controller_value={controller.settings.lights.lights_max}
          controls={controls}
        />
        <ShowSetting
          id="lights_min"
          cur_value={values.lights_min}
          controller_value={controller.settings.lights.lights_min}
          controls={controls}
        />
      </SettingGroupGrid>

      <SettingGroupGrid label={t('lights_sunrise_duration')}>
        <ShowSetting
          id="light_sunrise_duration"
          cur_value={values.light_sunrise_duration}
          controller_value={controller.settings.lights.light_sunrise_duration}
          controls={controls}
        />
      </SettingGroupGrid>

      <SettingGroupGrid label={t('over_temperature_hl')}>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="lights_dim_over_temp_enable" />
          </Typography>
          <EnableDisableSelect
            value={values.lights_dim_over_temp_enable}
            name="lights_dim_over_temp_enable"
            onChange={(event, value) => {
				controls.setValue ('lights_dim_over_temp_enable', event.target.value);
            }}
          />
        </ValueBox>
        <ShowSetting
          id="lights_dim_over_temp_temp_day"
          cur_value={values.lights_dim_over_temp_temp_day}
          controller_value={controller.settings.lights.lights_dim_over_temp_temp_day}
          min={controller.settings.climate.temp_sp_day / 10 + 1}
          controls={controls}
          type="float"
        />
        <ShowSetting
          id="lights_dim_over_temp_temp_co2"
          cur_value={values.lights_dim_over_temp_temp_co2}
          controller_value={controller.settings.lights.lights_dim_over_temp_temp_co2}
          min={controller.settings.co2.temp_sp_co2 / 10 + 1}
          controls={controls}
          type="float"
        />
        <ShowSetting
          id="lights_dim_over_temp_reduct_ph1"
          cur_value={values.lights_dim_over_temp_reduct_ph1}
          controller_value={controller.settings.lights.lights_dim_over_temp_reduct_ph1}
          min={values.lights_min - 1}
          max={values.lights_max}
          controls={controls}
          off_at={values.lights_min - 1}
        />
        <ShowSetting
          id="lights_dim_over_temp_reduct_ph2"
          cur_value={values.lights_dim_over_temp_reduct_ph2}
          controller_value={controller.settings.lights.lights_dim_over_temp_reduct_ph2}
          min={values.lights_min - 1}
          max={values.lights_dim_over_temp_reduct_ph1}
          controls={controls}
          off_at={values.lights_min - 1}
        />
        <ShowSetting
          id="lights_dim_over_temp_reduct_ph3"
          cur_value={values.lights_dim_over_temp_reduct_ph3}
          controller_value={controller.settings.lights.lights_dim_over_temp_reduct_ph3}
          min={values.lights_min - 1}
          max={values.lights_dim_over_temp_reduct_ph2}
          controls={controls}
          off_at={values.lights_min - 1}
        />
        <ShowSetting
          id="lights_dim_over_temp_reduct_wait_minute"
          cur_value={values.lights_dim_over_temp_reduct_wait_minute}
          controller_value={controller.settings.lights.lights_dim_over_temp_reduct_wait_minute}
          controls={controls}
        />
      </SettingGroupGrid>

      <SettingActions
        onReset={controls.resetSettings}
        onSave={controls.saveSettings}
        
      />
    </React.Fragment>
  );
}
