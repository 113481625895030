import {
  climate,
  co2,
  ventilation,
  timing,
  lights,
  system,
  multi_out,
  alarms,
  advanced
} from './settings';

import { resolutions } from './constants';

export const initialHistory = {
  time: [],
  temp: [],
  humidity: [],
  vpd: [],
  temp_leaf_current: [],
  co2: [],
  hidden: {
    humidity: false,
    temp: false,
    co2: true,
    vpd: true,
    temp_leaf_current: true
  },
  resolution: resolutions.recent,
  progress: 0,
  last_ts: 0,
  pending_data: []
};

export const settingsConfig = [
  { key: 'climate', value: climate },
  { key: 'co2', value: co2 },
  { key: 'ventilation', value: ventilation },
  { key: 'timing', value: timing },
  { key: 'lights', value: lights },
  { key: 'system', value: system },
  { key: 'multi_out', value: multi_out },
  { key: 'alarms', value: alarms },
  { key: 'advanced', value: advanced }
];
