import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { WarnLevels } from '../Constants/constants';
import _ from 'lodash';

export function writeTempSettings (controller) {
	if ( ! _.isEmpty (controller.temp_settings) )
	{
		localStorage.setItem ( `${controller.id}_temp_settings`, JSON.stringify (controller.temp_settings) );
	}
	else
	{
		localStorage.removeItem ( `${controller.id}_temp_settings`);
	}
}

export function settingControls (controller, tab_id, saveSettings, values, setValues) {
	return {
		saveTempSettings: (id, v) => {
			if (controller.temp_settings[tab_id] == null) controller.temp_settings[tab_id] = {};
			controller.temp_settings[tab_id][id] = v;
			// temp = temporary, _not_ temperature
			writeTempSettings (controller);
		},
		 // single setting
		resetSetting: function (id) {
			delete controller.temp_settings[tab_id][id];
			setValues({ ...values, [id]: controller.settings[tab_id][id] });
			delete controller.temp_settings[tab_id][id];
			writeTempSettings (controller);
		},
		// all settings
		resetSettings: function() {
			console.log ('remove temp setting 1');
			setValues ( _.cloneDeep (controller.settings[tab_id]) );
			delete controller.temp_settings[tab_id]
			writeTempSettings (controller);
		},
		
		restoreTempSettings: () => {
			setValues ( _.merge (_.cloneDeep (controller.settings[tab_id]), _.cloneDeep (controller.temp_settings[tab_id])) );
		},
		
		saveSettings: () => {
			saveSettings (controller, { ...values }, tab_id);
			delete controller.temp_settings[tab_id];
			writeTempSettings (controller);
		},
		
		setValue: function (id, v, saveTemp=true) {
			setValues ({...values, [id]: v});
			
			 // turn this off in slider's onChange to improve performance
			if (saveTemp) this.saveTempSettings (id, v);
		},
		
		setValueMinMaxSlider: function (id_min, id_max, v_min, v_max, saveTemp=true) {
			setValues ({...values, [id_min]: v_min, [id_max]: v_max});
			
			 // turn this of in slider's onChange to improve performance
			if (saveTemp) {
				this.saveTempSettings (id_min, v_min);
				this.saveTempSettings (id_max, v_max);
			}
		},
		
		resetMinMaxSlider: function (id_min, id_max) {
			delete controller.temp_settings[tab_id][id_min];
			delete controller.temp_settings[tab_id][id_max];
			setValues({ ...values,
						[id_min]: controller.settings[tab_id][id_min],
						[id_max]: controller.settings[tab_id][id_max]});
			
			writeTempSettings (controller);
		},
		
	};	
}

export const useSavedSettings = (controller, controls, sid=null) => {
  let { t } = useTranslation();
  let { enqueueSnackbar } = useSnackbar();
  let { tab_id } = useParams();
  tab_id = sid ?? tab_id;
  const [open_load_settings_dialog, setOpenLoadSettingsDialog] = useState(false);
  const [settings_loaded, setSettingsLoaded] = useState(false);
  
  useEffect(() => {
    // Get whether saved settings differ from controller settings.
    console.log ('useSavedSettings', controller);
    const differing = !_.isEmpty (controller.temp_settings[tab_id]) && !_.isEqual (controller.settings[tab_id], controller.temp_settings[tab_id]);
    
    const auto_load = localStorage.getItem('auto_load');
    setOpenLoadSettingsDialog(differing && !auto_load);
    // Check whether settings should be loaded (not yet loaded and differing)
    if (!settings_loaded && differing) {
      
      // Auto apply saved settings.
      if (auto_load === '1') {
        controls.resetSettings();
        enqueueSnackbar(t('auto_saved_loaded'), { variant: 'info' });
        setSettingsLoaded(true);
      }
      // Auto delete saved settings.
      else if (auto_load === '0') {
        controller.temp_settings[tab_id] = {}
        enqueueSnackbar(t('auto_saved_deleted'), { variant: 'info' });
      }
    }
    // Warning when leaving page although unsaved settings exist.
    return () => {
		console.log ('useSavedSettings leave', tab_id, !_.isEmpty (controller.temp_settings[tab_id]) );
	 if ( !_.isEmpty (controller.temp_settings[tab_id]) && !_.isEqual (controller.settings[tab_id], controller.temp_settings[tab_id]) )
	  {
		  enqueueSnackbar(t('auto_save_leaving_page_warn') + ' ' + t(tab_id), {
          variant: WarnLevels['unsaved_settings']
        });
	  }
    };
  }, []);

  return {
    openLoadSettingsDialog: open_load_settings_dialog,
    closeLoadSettingsDialog: () => {
      setOpenLoadSettingsDialog(false);
    }
  };
};
