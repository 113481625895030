import { red, green, yellow } from '@material-ui/core/colors';

export const statusColors = {
  good: '#95c11e',
  issues: yellow[500],
  bad: red[500]
};

export const WarnLevels = {
  unsaved_settings: 'info'
};

export const onOffMapping = {
  0: 'off',
  1: 'on'
};

export const offOnMapping = {
  0: 'on',
  1: 'off'
};

export const dayNightCo2PreheatMapping = {
  0: 'day',
  1: 'night',
  2: 'co2',
  3: 'preheat'
};

export const multiOutMapping = {
  0: 'exhaust_fans_ac',
  1: 'intake_fans_ac',
  2: 'humidifier',
  3: 'dehumidifier',
  4: 'lights',
  5: 'interval',
  6: 'heating_mat',
  7: 'chiller',
  8: 'co2_valve',
  9: 'co2_generator',
  10: 'heater',
  11: 'event1',
  12: 'event2',
  13: 'event3',
  14: 'event4',
  15: 'event1+2',
  16: 'event3+4',
  17: 'event1+2+3',
  18: 'event1+2+3+4',
  19: 'disabled',
  20: 'out_en_always',
  21: 'interv_1_inv',
  22: 'interval_2',
  23: 'interv_2_inv',
  24: 'event5',
  25: 'event6',
  26: 'event7',
  27: 'event8',
  28: 'event5+6',
  29: 'event5-8',
  30: 'event1-8'
};

export const eventGapMapping = {
  0: 'every_day',
  1: 'every_2_day',
  2: 'every_3_day',
  3: 'every_4_day',
  4: 'every_5_day',
  5: 'every_6_day',
  6: 'every_7_day',
  7: 'every_8_day',
  8: 'every_9_day',
  9: 'every_10_day',
  10: 'every_11_day',
  11: 'every_12_day',
  12: 'every_13_day',
  13: 'every_14_day',
  14: 'every_15_day',
  15: 'every_16_day',
  16: 'every_17_day',
  17: 'every_18_day',
  18: 'every_19_day',
  19: 'every_20_day',
  20: 'every_21_day',
  21: 'every_22_day',
  22: 'every_23_day',
  23: 'every_24_day',
  24: 'every_25_day',
  25: 'every_26_day',
  26: 'every_27_day',
  27: 'every_28_day'
};

export const alarmMapping = {
  alarm_low_temperature_air_day_state: {
    name: 'Low Temperatur Air Day',
    type: 'float',
    unit: '°C'
  },
  alarm_over_temperature_air_day_state: {
    name: 'Over Temperatur Air Day',
    type: 'float',
    unit: '°C'
  },
  alarm_low_temperature_air_night_state: {
    name: 'Low Temperatur Air Night',
    type: 'float',
    unit: '°C'
  },
  alarm_over_temperature_air_night_state: {
    name: 'Over Temperatur Air Night',
    type: 'float',
    unit: '°C'
  },
  alarm_low_temperature_co2_state: { name: 'Low Temperatur CO2', type: 'float', unit: '°C' },
  alarm_over_temperature_co2_state: { name: 'Over Temperatur CO2', type: 'float', unit: '°C' },
  alarm_low_co2_concentration_state: { name: 'Low CO2 Concentration', type: 'co2', unit: 'ppm' },
  alarm_over_co2_concentration_state: { name: 'Over CO2 Concentration', type: 'co2', unit: 'ppm' },
  alarm_low_rh_day_state: { name: 'Low RH Day', type: 'float', unit: '% RH' },
  alarm_over_rh_day_state: { name: 'Over RH Day', type: 'float', unit: '% RH' },
  alarm_low_rh_night_state: { name: 'Low RH Night', type: 'float', unit: '% RH' },
  alarm_over_rh_night_state: { name: 'Over RH Night', type: 'float', unit: '% RH' },
  alarm_low_vpd_state: { name: 'Low VPD Day', type: 'float', unit: 'hPa' },
  alarm_over_vpd_state: { name: 'Over VPD Day', type: 'float', unit: 'hPa' },
  alarm_low_vpd_night_state: { name: 'Low VPD Night', type: 'float', unit: 'hPa' },
  alarm_over_vpd_night_state: { name: 'Over VPD Night', type: 'float', unit: 'hPa' },
  alarm_no_connection_to_main_controller: {
    name: 'No active connection to controller',
    type: 'bool',
    unit: ''
  },
  alarm_sensor_error_rht_state: { name: 'Sensor Error RHT', type: 'bool', unit: '' },
  alarm_sensor_error_co2_state: { name: 'Sensor Error Co2', type: 'bool', unit: '' },
  alarm_sensor_error_pressure_state: { name: 'Sensor Error Pressure', type: 'bool', unit: '' },
  alarm_sensor_error_heating_mat_state: {
    name: 'Sensor Error Heating Mat',
    type: 'bool',
    unit: ''
  },
  alarm_sensor_error_leaf_state: { name: 'Sensor Error Leaf', type: 'bool', unit: '' },
  alarm_error_power_extender_state: { name: 'Sensor Error Power Extender', type: 'bool', unit: '' },
  alarm_sensor_error_intake_enable: { name: 'Sensor Error Intake', type: 'bool', unit: '' }
};

export const alarm_threshold = {
  temp: 2,
  co2: 100,
  rh: 5,
  vpd: 2
};

// Infos
// undefined means the values depends on other values and is computed on the page directly.
export const settings_borders_and_units = {
  humidity_sp_day: [10, 95, '  % RH'],
  humidity_sp_night: [10, 95, '  % RH'],
  temp_sp_day: [10, 50, '  °C'],
  temp_sp_night: [10, 50, '  °C'],
  vpd_sp: [1, 20, '  hPa'],
  vpd_sp_night: [1, 20, '  hPa'],
  temp_sp_heater_day: [4.9, undefined, '  °C'],
  temp_sp_heater_night: [5, undefined, '  °C'],
  temp_sp_heating_mat_day_rel: [0, 10, '  °C'],
  temp_sp_heating_mat_night_rel: [0, 10, '  °C'],
  temp_sp_heating_mat_day_abs: [5, 40, '  °C'],
  temp_sp_heating_mat_night_abs: [5, 40, '  °C'],
  preheat_night_day: [0, 120, '  min'],

  // co2-settings
  co2_sp: [500, 1800, '  ppm'],
  room_vol_sp: [0.1, 6500, '  m³'],
  co2_flow: [2, 100, ' l/min'],
  exhaust_fans_flow: [0, 60000, ' m³/h'],
  temp_sp_co2: [10, 50, ' °C'],
  temp_heater_co2: [4.9, undefined, ' °C'],
  // EC fans co2
  exhaust_fan_ec_max_co2: [undefined, undefined, ' %'],
  exhaust_fan_ec_min_co2: [undefined, undefined, ' %'],
  intake_fan_ec_max_co2: [undefined, undefined, ' %'],
  intake_fan_ec_min_co2: [undefined, undefined, ' %'],
  // AC fans co2
  exhaust_fan_ac_max_co2: [undefined, undefined, ' %'],
  exhaust_fan_ac_min_co2: [undefined, undefined, ' %'],
  intake_fan_ac_max_co2: [undefined, undefined, ' %'],
  intake_fan_ac_min_co2: [undefined, undefined, ' %'],

  // ventilation-settings
  // EC fans day/night
  exhaust_fan_ec_max_day: [undefined, undefined, ' %'],
  exhaust_fan_ec_min_day: [undefined, undefined, ' %'],
  exhaust_fan_ec_max_night: [undefined, undefined, ' %'],
  exhaust_fan_ec_min_night: [undefined, undefined, ' %'],
  intake_fan_ec_max_night: [undefined, undefined, ' %'],
  intake_fan_ec_min_night: [undefined, undefined, ' %'],
  intake_fan_ec_max_day: [undefined, undefined, ' %'],
  intake_fan_ec_min_day: [undefined, undefined, ' %'],
  // AC fans day/night
  exhaust_fan_ac_max_day: [undefined, undefined, ' %'],
  exhaust_fan_ac_min_day: [undefined, undefined, ' %'],
  exhaust_fan_ac_max_night: [undefined, undefined, ' %'],
  exhaust_fan_ac_min_night: [undefined, undefined, ' %'],
  intake_fan_ac_max_night: [undefined, undefined, ' %'],
  intake_fan_ac_min_night: [undefined, undefined, ' %'],
  intake_fan_ac_max_day: [undefined, undefined, ' %'],
  intake_fan_ac_min_day: [undefined, undefined, ' %'],

  deviation_rpm_reduction: [0, 10, ' °C'],
  pressure_percent_day: [-50, 100, ' %'],
  pressure_percent_night: [-50, 100, ' %'],
  pressure_pa: [2, 100, ' Pa'],

  lights_max: [10, 115, ' %'],
  lights_min: [9, 100, ' %'],
  light_sunrise_duration: [0, 90, ' min'],
  lights_dim_over_temp_temp_day: [undefined, 50, ' °C'],
  lights_dim_over_temp_temp_co2: [undefined, 50, ' °C'],
  lights_dim_over_temp_reduct_ph1: [undefined, undefined, ' %'],
  lights_dim_over_temp_reduct_ph2: [undefined, undefined, ' %'],
  lights_dim_over_temp_reduct_ph3: [undefined, undefined, ' %'],
  lights_dim_over_temp_reduct_wait_minute: [1, 60, ' min'],

  fan_ac_intake_turnoff: [10, 80, ' %'],
  fan_ac_exhaust_turnoff: [10, 80, ' %'],
  intake_sens_min_diff_rh: [0, 25, ' % RH'],
  intake_sens_min_diff_temp: [0, 10, ' °C'],
  intake_sens_retry_wait: [0, 300, 'min'],
  intake_sens_retry_duration: [0, 300, 'sec'],
  rh_min_with_vpd: [50, undefined, ' % RH'],
  rh_max_with_vpd: [undefined, 950, ' % RH'],

  leaf_temp_diff: [0, 7, ' °C'],
  hysteresis_co2: [30, 200, ' ppm'],
  reg_kd_pressure: [1, 50, ''],
  reg_kd_temp_air: [1, 50, ''],
  interval_ramp_temp_sp: [1, 120, ' min'],
  hysteresis_humidifier: [0.2, 30, ' (% RH)'],
  humidifier_kd: [0, 500, ''],
  hysteresis_dehumidifier: [1, 30, ' (% RH)'],
  offset_sp_dehumidifier: [2, 30, ' % RH'],
  hysteresis_heater: [0.1, 5, ' °C'],
  hysteresis_chiller_low: [0.5, 10, ' °C'],
  hysteresis_chiller_high: [0.5, 10, ' °C'],
  co2_min_dose_ms: [100, 5000, ' ms'],
  lights_dim_over_temp_hyst: [0, 5, ' °C'],
  lights_dim_over_temp_delay_sec: [10, 600, ' sec'],
  offset_sp_humidifier: [0, 5, ' % RH'],
  dehumid_exhaust_threshold: [0, 25, ' °C'],
  dehumid_exhaust_temp_untercut_max_allowed: [1, 21, ' °C'],

  filter_val_sensor_co2: [0, 7, ''],
  calib_val_sensor_co2: [0, 2000, ' ppm'],

  // Alarms
  alarm_low_temperature_air_day_setting: [5, undefined, ' °C'], // undefined set in html code
  alarm_over_temperature_air_day_setting: [undefined, 50, ' °C'],
  alarm_low_temperature_air_night_setting: [5, undefined, ' °C'],
  alarm_over_temperature_air_night_setting: [undefined, 50, ' °C'],
  alarm_low_temperature_air_co2_setting: [5, undefined, ' °C'],
  alarm_over_temperature_air_co2_setting: [undefined, 50, ' °C'],
  alarm_low_co2_concentration_setting: [300, undefined, ' ppm'],
  alarm_over_co2_concentration_setting: [undefined, 5000, ' ppm'],
  alarm_low_rh_day_setting: [10, undefined, ' % RH'],
  alarm_over_rh_day_setting: [undefined, 95, ' % RH'],
  alarm_low_rh_night_setting: [0, undefined, ' % RH'],
  alarm_over_rh_night_setting: [undefined, 95, ' % RH'],
  alarm_low_vpd_setting: [2, undefined, ' hPa'],
  alarm_over_vpd_setting: [undefined, 50, ' hPa'],
  alarm_low_vpd_night_setting: [2, undefined, ' hPa'],
  alarm_over_vpd_night_setting: [undefined, 50, ' hPa'],
  expected_power_extender_out_cnt: [1, 20, ''],

  // timing
  cur_grow_cycle_day: [1, 100, '']
};

export const history_values = ['temp', 'humidity', 'vpd', 'temp_leaf_current', 'co2'];

export const room_scale_mapping = {
  room_vol_sp: { 0: 30, 1: 1000, 2: 6500 },
  exhaust_fans_flow: { 0: 3000, 1: 10000, 2: 60000 },
  co2_flow: { 0: 20, 1: 50, 2: 999.9 }
};

export const controller_type_mapping = {
  5053: 'GrowBase',
  5055: 'GrowBase Pro'
};

export const resolutions = {
  recent: 4,
  daily: 240,
  full: 1800
};

export const firmware_version_infos = {
  5055: [
    {
      text: '5055.0.01.00b DE',
      link: 'https://docs.growcontrol.de/fw505500100b_GrowBase_Pro_WiFi_Beta_Release_Note_DE.pdf'
    },
    {
      text: '5055.0.01.00b EN',
      link: 'https://docs.growcontrol.de/fw505500100b_GrowBase_Pro_WiFi_Beta_Release_Note_EN.pdf'
    }
  ],
  5053: [
    {
      text: '5053.0.01.00b DE',
      link: 'https://docs.growcontrol.de/fw505300100b_GrowBase_WiFi_Beta_Release_Note_DE.pdf'
    },
    {
      text: '5053.0.01.00b EN',
      link: 'https://docs.growcontrol.de/fw505300100b_GrowBase_WiFi_Beta_Release_Note_EN.pdf'
    }
  ]
};

export const initialRequests = [
  'request_values_full',
  'get_full_alarm_log',
  'get_all_messages',
  'get_recent_history',
  'get_info_graphs'
];
