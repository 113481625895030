import {
  BinaryToUintArray
} from '../Utils/utils.js';
import { settings_borders_and_units, alarmMapping } from '../Constants/constants';
import { CreateSimulationInit } from '../Utils/simulation_utils.js';

import simulationOnMessage from './simulationOnMessage.js';


export function createSimulation (address, controller, user, wasm) {
	let simulation = {
		socket: null,
		
		init()
		{
			this.controller = controller;
			this.user = user;
			this.wasm = wasm;
			this.socket = new WebSocket (address);
			this.socket.binaryType = "arrayBuffer";
			
			this.socket.onopen = () => {
				this.sendWSMsg (
					CreateSimulationInit (this.socket, controller.name, controller.type, user)
				);
			};
			
			this.socket.onmessage = (event) => {
				// convert binary blob to JSON
				try {
					const reader = new FileReader();
					reader.onload = () => {
						const binary_str = reader.result;
						const len = binary_str.length;
						const new_data = wasm.fromBinary(BinaryToUintArray(binary_str, len), len);
						
						simulationOnMessage ( this, JSON.parse (new_data) );
					};
					reader.readAsBinaryString(event.data);
				
				} catch (e) {
					console.log('[global] Failed parsing json: ', event.data, e);
				}
			};
			
			// Interval sending keep-alive for simulation.
			this.peng = setInterval ( () => {
				if (this.socket.readyState === 1)
				{
					this.sendWSMsg ({ command: 'peng', internal_id: '' });
				}
			}, 5000);
		},
		
		
		
		sendWSMsg: function (req)
		{
			if (req && wasm.toBinary) {
				// Add cookie
				req.cookie = document.cookie;
				// Get binary string through webassembly
				const json_str = JSON.stringify (req);
				//console.log ('sim json_str', json_str);
				
				const binary_str = this.wasm.toBinary(json_str);
				let binaryData = new Uint8Array(
					atob(binary_str)
					.split('')
					.map(function (c) {
						return c.charCodeAt(0);
					})
				);
				
				// Convert to unit8 array
				// Finally send data
				
				this.socket.send (binaryData);
			}
		},
		
		send_alarm (event, alarm) {
			// add alarms
			let alarms = {};
			if (alarm.name === 'all') {
				for (const key in alarmMapping) {
					const borders_key = key.replace('state', 'setting');
					let value = 1;
					if (borders_key in settings_borders_and_units) {
						value =
							key.indexOf('low') !== -1
								? settings_borders_and_units[borders_key][0]
								: settings_borders_and_units[borders_key][1];
					}
					
					alarms[key] = parseInt(value);
				}
			} else alarms[alarm.name] = parseInt(alarm.value);

			// Construct request.
			var json_request = {
				internal_id: this.controller.id,
				cookie: document.cookie,
				command: 'alarms_triggered',
				data: { alarms: alarms, timestamp: Date.now() }
			};
			
			this.socket.send ( JSON.stringify (json_request) );
		},
		
		resolve_alarm (event, alarm) {
			// add alarm key(s)
			let alarm_keys = [];
			if (alarm.name === 'all') {
				for (const key in alarmMapping) alarm_keys.push(key);
			} else {
				alarm_keys.push(alarm.name);
			}
			// Construct request.
			var json_request = {
				internal_id: this.controller.id,
				cookie: document.cookie,
				command: 'alarms_resolved',
				data: { timestamp: Date.now(), alarm_keys: alarm_keys }
			};
			
			this.socket.send ( JSON.stringify (json_request) );
		},
		
		stop() {
			clearInterval (this.peng);
			this.socket.close();
		}
	};
	
	simulation.init();
	return simulation;
}
