import React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';

export const languageMap = {
  en: { label: 'English', dir: 'ltr', active: true },
  de: { label: 'Deutsch', dir: 'ltr', active: false },
  fr: { label: 'Français', dir: 'ltr', active: false },
  es: { label: 'Español', dir: 'ltr', active: false },
  it: { label: 'Italiano', dir: 'ltr', active: false },
  keys: { label: 'Keys', dir: 'ltr', active: false }
};

const LanguageSelect = ({ setLang }) => {
  const selected = i18next.language;
  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  React.useEffect(() => {
    document.body.dir = languageMap[selected].dir;
  }, [menuAnchor, selected]);

  return (
    <React.Fragment>
      <Button
        color="inherit"
        onClick={(event) => {
          event.stopPropagation();
          setMenuAnchor(event.target);
        }}
      >
        {selected}
        <ArrowDropDown fontSize="small" />
      </Button>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <div>
          <List>
            <ListSubheader>{t('select_language')}</ListSubheader>
            {Object.keys(languageMap)?.map((item) => (
              <ListItem
                button
                key={item}
                onClick={(e) => {
                  e.stopPropagation();
                  i18next.changeLanguage(item);
                  localStorage.setItem('i18nextLng', item);
                  setMenuAnchor(null);
                  if (setLang !== undefined) setLang(item); // set meta lang
                }}
              >
                {languageMap[item].label}
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </React.Fragment>
  );
};

export default LanguageSelect;
