import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import MultiOutLabel, { MultiOutHeading } from '../FormWidgets/MultiOutLabel';
import { NormalValue, NegativeValue, FloatValue } from '../FormWidgets/InfoValues';
import Desc from '../FormWidgets/Description';
import { offOnMapping, dayNightCo2PreheatMapping } from '../Constants/constants';
import { SettingGroup, ValueBox } from '../FormWidgets/SettingGroup';

import useStateManager from '../StateManager.js';
import { subscribe } from 'valtio'

function range(start, count, inc) {
  var foo = [];
  for (var i = 0; i < count; i += inc) foo.push(start + i);
  return foo;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  datasetText: {
    marginTop: 35,
    marginBottom: '-20px'
  },
  graphData: {
    width: '100%',
    height: '100px'
  }
}));

export default function Info({
  contType,
  phase,
  ...others
}) {

  const classes = useStyles();
  let { id } = useParams();
  let { t } = useTranslation();
  
  let time_points = 180;
  
  const stateManager = useStateManager();
  const controller = stateManager.getControllerById (id);

  const [options] = useState({
    responsive: true,
    maintainAspectRatio: false,
    defaultColors: ['#95c11e'],
    elements: {
      point: { radius: 0 },
      line: { backgroundColor: '#95c11e', borderColor: '#95c11e', width: '100%' }
    },
    scales: {
      //yAxes: [{ gridLines: { drawBorder: false } }],
      //xAxes: [{ gridLines: { display: false } }],
      x: {
        grid: { display: false, drawBorder: false },
        ticks: { display: false }
      },
      y: {
        grid: { display: false, drawBorder: false },
        ticks: { display: false }
      }
    },
    plugins: { legend: { display: false } }
  });

  const [humidityData, setHumidityData] = useState({
    labels: [...Array(time_points).keys()],
    datasets: [
      {
        label: 'humidity',
        data: controller.infos.humidity_graph ?? [],
        hidden: false,
        tension: 0.5,
        unitPrefix: ' % RH'
      }
    ]
  });


  const [tempData, setTempData] = useState({
    labels: [...Array(time_points).keys()],
    datasets: [
      {
        label: 'temp',
        data: controller.infos.temp_graph ?? [],
        hidden: false,
        tension: 0.5,
        unitPrefix: ' °C'
      }
    ]
  });

  useEffect(() => {
	function updateHumidity() {
		console.log ('_update humid');
		const curHumidityData = humidityData;
		  curHumidityData.datasets[0].data = controller.infos.humidity_graph;
		  setHumidityData({
			labels: [...humidityData.labels],
			datasets: [...curHumidityData.datasets]
		  });
	}
	
	function updateTemp() {
		const curTempData = tempData;
		curTempData.datasets[0].data = controller.infos.temp_graph;
		setTempData({ labels: [...tempData.labels], datasets: [...curTempData.datasets] });
	}
	updateHumidity();
	updateTemp();
	const unsubscribeHumidity = subscribe (controller.infos, updateHumidity);
	const unsubscribeTemp = subscribe (controller.infos, updateTemp);
    
    return () => {
		unsubscribeHumidity();
		unsubscribeTemp();
	};
  }, []);


  console.log("DEBUG: ", controller.infos, controller.settings);
  let cur_values = controller.infos;
  let ec_fans = controller.settings.ec_fans;
  let multi_out_count = controller.settings.multi_out_values.out_count ?? 0;


  return (
    <React.Fragment>
      {/* Climate Values */}
      <SettingGroup label={t('climate_values')}>
        <Grid container spacing={0.2}>
          <Grid item md={6} xs={12}>
            <Box style={{ width: '100%' }}>
              <ValueBox>
                <Box justifyContent={'space-between'} display={'flex'}>
                  <Typography gutterBottom>
                    <Desc id={'humidity'} />
                  </Typography>

                  <Typography gutterBottom sx={{ paddingRight: 2 }}>
                    <Desc id={'info_humidity_sp'} />
                    { (cur_values.info_humidity_sp ?? -1) !== -1
                      ? parseFloat(cur_values.info_humidity_sp / 10).toFixed(1)
                      : 'n/a'}
                  </Typography>
                </Box>

                <FloatValue name="humidity" value={cur_values.humidity ?? -1} unit="% RH" />

                {humidityData.datasets[0].data.length > 5 && (
                  <>
                    <Box className={classes.graphData}>
                      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography>
                          {parseFloat(
                            Math.max(...humidityData.datasets[0].data.filter(Boolean))
                          ).toFixed(1)}
                        </Typography>
                        <Typography>24h</Typography>
                      </Box>
                      <Line data={humidityData} options={options} />
                    </Box>
                    <Typography className={classes.datasetText} gutterBottom>
                      {parseFloat(
                        Math.min(...humidityData.datasets[0].data.filter(Boolean))
                      ).toFixed(1)}
                    </Typography>
                  </>
                )}
              </ValueBox>
            </Box>
          </Grid>

          <Grid item md={6} xs={12}>
            <Box style={{ width: '100%' }}>
              <ValueBox>
                <Box justifyContent={'space-between'} display={'flex'}>
                  <Typography gutterBottom>
                    <Desc id={'temp'} />
                  </Typography>

                  <Typography gutterBottom>
                    <Desc id={'info_temp_sp'} />
                     { cur_values.info_temp_sp ?? -1 !== -1
                      ? parseFloat(cur_values.info_temp_sp / 10).toFixed(1)
                      : 'n/a'}
                  </Typography>
                </Box>

                <FloatValue name="temp" value={cur_values.temp ?? -1} unit="°C" />

                {tempData.datasets[0].data.length > 5 && (
                  <>
                    <Box className={classes.graphData}>
                      <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography>
                          {parseFloat(
                            Math.max(...tempData.datasets[0].data.filter(Boolean))
                          ).toFixed(1)}
                        </Typography>
                        <Typography>24h</Typography>
                      </Box>
                      <Line data={tempData} options={options} />
                    </Box>
                    <Typography className={classes.datasetText} gutterBottom>
                      {parseFloat(Math.min(...tempData.datasets[0].data.filter(Boolean))).toFixed(
                        1
                      )}
                    </Typography>
                  </>
                )}
              </ValueBox>
            </Box>
          </Grid>

          <Grid item md={4} xs={12}>
            {contType === 5055 && (
              <ValueBox>
                <Typography gutterBottom>
                  <Desc id={'vpd'} />
                </Typography>
                <FloatValue name="vpd" value={cur_values.vpd ?? -1} unit="hPa" />
              </ValueBox>
            )}
          </Grid>
          <Grid item md={4} xs={12}>
            {contType === 5055 && (
              <ValueBox>
                <Typography gutterBottom>
                  <Desc id={'temp_leaf_current'} />
                </Typography>
                <FloatValue
                  name="temp_leaf_current"
                  value={cur_values.temp_leaf_current ?? -1}
                  unit="°C"
                />
              </ValueBox>
            )}
          </Grid>
          <Grid item md={4} xs={12}>
            <ValueBox>
              <Typography gutterBottom>
                <Desc id={'co2'} />
              </Typography>
              <NormalValue name="co2" value={cur_values.co2 ?? -1} unit="ppm" />
            </ValueBox>
          </Grid>
        </Grid>
      </SettingGroup>

      {/* More */}
      <SettingGroup label={t('more')}>
        {contType === 5055 && (
          <ValueBox>
            <Typography gutterBottom>
              <Desc id={'rh_intake_current'} show="humidity_intake" />
            </Typography>
            <FloatValue
              name="rh_intake_current"
              value={cur_values.rh_intake_current ?? -1}
              unit="% RH"
              style={{ fontSize: '1.3rem' }}
            />
          </ValueBox>
        )}

        {contType === 5055 && (
          <ValueBox>
            <Typography gutterBottom>
              <Desc id={'temp_intake_current'} show="temp_intake" />
            </Typography>
            <FloatValue
              name="temp_intake_current"
              value={cur_values.temp_intake_current ?? -1}
              unit="°C"
              style={{ fontSize: '1.3rem' }}
            />
          </ValueBox>
        )}
        <ValueBox>
          <Typography gutterBottom>
            <Desc id={'temp_heating_mat'} />
          </Typography>
          <FloatValue
            name="temp_heating_mat"
            value={cur_values.temp_heating_mat ?? -1}
            unit="°C"
            style={{ fontSize: '1.3rem' }}
          />
        </ValueBox>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id={'neg_pressure'} />
          </Typography>
          <NegativeValue
            name="neg_pressure"
            value={cur_values.neg_pressure ?? -1}
            unit="Pa"
            style={{ fontSize: '1.3rem' }}
          />
        </ValueBox>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id={'info_lights'} />
          </Typography>
          <NormalValue
            name="info_lights"
            value={cur_values.info_lights ?? -1}
            unit="%"
            style={{ fontSize: '1.3rem' }}
          />
        </ValueBox>
        <ValueBox style={{ minWidth: '250px' }}>
          <Typography gutterBottom>
            <Desc id={'info_interval_timer'} />
          </Typography>
          <label name="info_interval_timer">
            <NormalValue
              name="onoffswitch"
              value={t(offOnMapping[cur_values.info_interval_timer_active ?? -1])}
              unit=""
              style={{ fontSize: '1.3rem' }}
            />{' '}
            in
            <NormalValue
              name="intervaltimer"
              value={cur_values.info_interval_timer ?? -1}
              unit=""
              style={{ fontSize: '1.3rem' }}
            />
          </label>
        </ValueBox>
        {contType === 5055 && (
          <ValueBox style={{ minWidth: '250px' }}>
            <Typography gutterBottom>
              <Desc id={'info_interval_2_timer'} />
            </Typography>
            <label name="info_interval_2_timer">
              <NormalValue
                name="onoffswitch"
                value={t(offOnMapping[cur_values.info_interval_2_timer_active ?? -1])}
                unit=""
                style={{ fontSize: '1.3rem' }}
              />{' '}
              in
              <NormalValue
                name="intervaltimer"
                value={cur_values.info_interval_2_timer}
                unit=""
                style={{ fontSize: '1.3rem' }}
              />
            </label>
          </ValueBox>
        )}

        <ValueBox style={{ minWidth: '150px' }}>
          <Typography gutterBottom>
            <Desc id={'time'} />
          </Typography>
          <NormalValue
            name="time"
            value={ cur_values.time ?? '' }
            unit=""
            style={{ fontSize: '1.3rem' }}
          />
        </ValueBox>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id={'date'} />
          </Typography>
          <NormalValue
            name="date"
            value={ cur_values.date ?? '' }
            unit=""
            style={{ fontSize: '1.3rem' }}
          />
        </ValueBox>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id={'phase'} />
          </Typography>
          <label name="phase">
            <NormalValue
              name="phasevalue"
              value={t(dayNightCo2PreheatMapping[cur_values.phase ?? -1])}
              unit=""
              style={{ fontSize: '1.3rem' }}
            />
          </label>
        </ValueBox>

        {contType === 5055 && (
          <ValueBox>
            <Typography gutterBottom>
              <Desc id={'temp_heater_setpoint_info'} />
            </Typography>
		
			{(cur_values.temp_heater_setpoint_info ?? -1) > 49 && (
				<FloatValue
				  name="temp_heater_setpoint_info"
				  value={cur_values.temp_heater_setpoint_info ?? -1}
				  unit="°C"
				  style={{ fontSize: '1.3rem' }}
				/>
			)}
			{(cur_values.temp_heater_setpoint_info ?? -1) <= 49 && (
				<NormalValue
				  name="phasevalue"
				  value={t("off")}
				  unit=""
				  style={{ fontSize: '1.3rem' }} />
			)}
          </ValueBox>
        )}
        <ValueBox style={{ minWidth: '200px' }}>
          <Typography gutterBottom>
            <Desc id={'ec_fans_intake'} />
          </Typography>
          <NormalValue
            name="ec_fans_intake_out"
            value={ec_fans.ec_fans_intake_out ?? -1}
            unit="% "
            style={{ fontSize: '1.3rem' }}
          />
          <NormalValue
            name="ec_fans_intake_rpm"
            value={ec_fans.ec_fans_intake_rpm ?? -1}
            unit="rpm"
            style={{ fontSize: '1.3rem' }}
          />
        </ValueBox>
        <ValueBox style={{ minWidth: '200px' }}>
          <Typography gutterBottom>
            <Desc id={'ec_fans_exhaust'} />
          </Typography>
          <NormalValue
            name="ec_fans_exhaust_out"
            value={ec_fans.ec_fans_exhaust_out ?? -1}
            unit="% "
            style={{ fontSize: '1.3rem' }}
          />
          <NormalValue
            name="ec_fans_exhaust_rpm"
            value={ec_fans.ec_fans_exhaust_rpm ?? -1}
            unit="rpm"
            style={{ fontSize: '1.3rem' }}
          />
        </ValueBox>
      </SettingGroup>
      <SettingGroup
        label={t('multifunctional_outputs_heading') + ' (' + multi_out_count + ')'}
      >
        {range(1, multi_out_count, 1).map((index, i) => (
          <ValueBox key={'multi_out_elem_' + i}>
            <MultiOutHeading
              index={index}
              memo={controller.settings['multi_out']['out_' + index + '_memo'] ?? ''}
            />
            <MultiOutLabel
              func={controller.settings['multi_out']['out_' + index + '_func'] ?? ''}
              value={controller.settings['multi_out_values']['out_' + index + '_state'] ?? ''}
            />
          </ValueBox>
        ))}
      </SettingGroup>
    </React.Fragment>
  );
}
