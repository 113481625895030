import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha
} from 'react-simple-captcha';

import { useTranslation } from 'react-i18next';
import { useAddress } from '../Hooks/addressHook';

const mm = {
  marginTop: 10
};

export default function ResetPage({ ...props }) {
  const [password, setPassword] = useState(null);
  const [password_2, setPassword2] = useState(null);
  const [captcha, setCaptcha] = useState(null);
  let { t } = useTranslation();
  const address = useAddress();

  useEffect(() => {
    const timer = setTimeout(() => {
      loadCaptchaEnginge(6);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const validateForm = (event) => {
    if (!validateCaptcha(captcha)) {
      alert(t('invalid_captcha'));
    } else if (password && password_2) {
      if (password !== password_2) {
        alert(t('set_password_error_missmatch'));
        return;
      }
      const request = { password: password };

      // Key key and username:
      let url = window.location.href;
      const questionMarkIndex = url.indexOf('?');
      if (questionMarkIndex !== -1)
        url = url.substring(questionMarkIndex);
      const params = new URLSearchParams(url);
      const key = params.get('key');
      const username = params.get('username');

      console.log("Got params:", key, username);

      fetch(address.api_url('/api/set_new_password?key='+key+'&username='+username), {
        method: 'POST',
        body: JSON.stringify(request)
      }).then((response) => {
        if (response.ok) {
          alert(t('set_password_success'));
        } else if (response.status === 401) {
          alert(t('set_password_error_unauthenticated'));
        } else if (response.status === 403) {
          alert(t('set_password_error_strength'));
        } else {
          alert(t('set_password_error_unknown'));
        }
      });
    } else {
      alert('Missing entry');
    }
  };

  return (
    <Container style={mm}>
      <TextField
        autoFocus
        margin="dense"
        id="serial"
        label="new password"
        type="password"
        fullWidth
        onChange={(event) => setPassword(event.target.value)}
      />
      <TextField
        margin="dense"
        id="serial"
        label="repeat password"
        type="password"
        fullWidth
        onChange={(event) => setPassword2(event.target.value)}
      />

      <TextField
        margin="dense"
        id="captcha"
        label="Captcha"
        type="text"
        variant="filled"
        onChange={(event) => setCaptcha(event.target.value)}
      />

      <div>
        <LoadCanvasTemplateNoReload />
      </div>

      <Button onClick={validateForm} color="primary">
        Reset Password
      </Button>
    </Container>
  );
}
