import momentWithLocale from '../local_moment';

import createModule from '../glue.mjs';

export const wasm = createModule().then ( (Module) => ({
	fromBinary: Module.cwrap('MsgpackToString', 'string', ['array', 'number']),
	toBinary: Module.cwrap('StringToMsgpack', 'string', ['string'])
}));

export function CreateControllerWSRequest(socket, controller_id, command, data = {}) {
  let req = {};
  req.internal_id = controller_id;
  req.cookie = document.cookie;
  req.data = data;
  req.command = command;
  req.socket = socket;
  return req;
}

export function CreateGlobalWSRequest(socket, command, data = {}) {
  let req = {};
  req.cookie = document.cookie;
  req.internal_id = '###global###';
  req.data = data;
  req.command = command;
  req.socket = socket;
  return req;
}

export function HandleAction (wsManager, controller, type, value) {
  let data = { id: type, value: 0 };
  if (value !== undefined) {
    if (Number.isInteger(Number.parseInt(value, 10))) data['value'] = Number.parseInt(value, 10);
    else return;
  }

  wsManager.sendWSMsg (controller, 'action', data);
}

export function DeepCopyObj(obj) {
  return JSON.parse(JSON.stringify(obj));
}

function compare_alarm_log_entries_by_timestamp(log_entry_a, log_entry_b) {
  if (log_entry_a['ts'] < log_entry_b['ts']) return -1;
  if (log_entry_a['ts'] > log_entry_b['ts']) return 1;
  return 0;
}

function SortAlarmLog(alarm_log) {
  alarm_log.sort(compare_alarm_log_entries_by_timestamp);
  return alarm_log;
}

function RemoveAlarmLogDuplicates(alarm_log) {
  let new_alarm_log = [];
  alarm_log.forEach((log_entry) => {
    const existing = new_alarm_log.filter((x, i) => {
      return x['id'] === log_entry['id'] && x['triggered'] === log_entry['triggered'];
    });
    if (existing.length === 0) new_alarm_log.push(log_entry);
  });
  return new_alarm_log;
}

export function EditAlarmLog(alarm_log) {
  alarm_log = RemoveAlarmLogDuplicates(alarm_log);
  alarm_log = SortAlarmLog(alarm_log);
  // alarm_log = alarm_log.slice(0,10);
  return alarm_log;
}

export function AlarmInList(alarm_id, new_alarms, controller_id) {
  for (let i = 0; i < new_alarms.length; i++) {
    if (controller_id + '_' + new_alarms[i] === alarm_id) {
      return true;
    }
  }
  return false;
}

export function RemoveMessageDuplicates(messages) {
  let new_messages = [];
  messages.forEach((message) => {
    const existing = new_messages.filter((x, i) => {
      return x['id'] === message['id'];
    });
    if (existing.length === 0) new_messages.push(message);
  });
  return new_messages;
}

export function RemoveMessageOfController(messages, controller_name) {
  let new_messages = [];
  messages.forEach((message) => {
    if (message['controller'] !== controller_name) new_messages.push(message);
  });
  return new_messages;
}

export function Ran(min, max, without) {
  while (true) {
    var num = Number.parseInt(Math.random() * (max + 1 - min) + min, 10);
    if (num !== without) return num;
  }
}


export function addZ(value) {
  return value < 10 ? '0' + value : value;
}

export function ParseFloat(number, format) {
  let str = Number.parseFloat(number).toFixed(1);
  return format === '.' ? str : str.replace('.', format);
}

export function TransformHistoryValue(value, type) {
  let r_val;
  if (value === -1 || value === 0xffff) r_val = -1;
  else if (type === 'co2' || type === 'time') r_val = value;
  else r_val = Number.parseFloat(value / 10);
  return r_val;
}

export function UpdateHistory(history, data) {
  // Update history-infos
  history.pending_data.push(data);
  history.progress = data['progress'];
  if (data.time.length > 0) {
    history.last_ts = data.time[data.time.length - 1];
  } else {
    var date = new Date();
    history.last_ts = date.getTime();
  }
  // If all data have arived, sort and then add.
  if (data['progress'] === 100) {
    history.pending_data.sort((a, b) => a.part - b.part);
    ClearHistoryValues(history);
    for (const data of history.pending_data) UpdateHistoryValues(history, data);
    history.pending_data = [];
  }
  return history;
}

function ClearHistoryValues(cur) {
  for (const type of ['temp', 'humidity', 'vpd', 'temp_leaf_current', 'co2', 'time'])
    cur[type] = [];
}

function UpdateHistoryValues(cur, next) {
  for (const type of ['temp', 'humidity', 'vpd', 'temp_leaf_current', 'co2', 'time']) {
    cur[type].push(...next[type].map((val) => TransformHistoryValue(val, type)));
  }
}

export function PrintDate(timestamp, format) {
  if (timestamp !== undefined && timestamp > 10)
    return momentWithLocale(timestamp).utc().format(format);
  return 'n/a';
}

// safely handles circular references
export function dump (obj, indent = 2) {
  let cache = [];
  console.log ( JSON.stringify(
    obj,
    (key, value) =>
      typeof value === "object" && value !== null
        ? cache.includes(value)
          ? undefined // Duplicate reference found, discard key
          : cache.push(value) && value // Store value in our collection
        : value,
    indent
  ) );
  cache = null;
}


/*export async function binaryBlob2JSON (blob)
{
	await new Promise ( (resolve, reject) => {
		try
		{
			const reader = new FileReader();
			reader.onload = () => {
				var i, l, d, array;
				d = reader.result;
				l = d.length;
				array = new Uint8Array(l);
				for (var i = 0; i < l; i++) {
					array[i] = d.charCodeAt(i);
				}
				const new_data = wasm.fromBinary (array, l);
				resolve (new_data);
				
			};
			
			reader.readAsBinaryString (blob);
		}
		catch (e)
		{
			console.log('[global] Failed parsing json: ', blob, e);
		}
		
		reject();
	});
}*/

export function BinaryToUintArray(binary_str, len) {
  var array = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    array[i] = binary_str.charCodeAt(i);
  }
  return array;
}


export function setImmediatelyInterval (func, delay) {
	func();
	return setInterval (func, delay);
}
