import React, { useState, useContext, createContext, useEffect } from 'react';
const addressContext = createContext();

export function useAddress() {
  return useContext(addressContext);
}

function getCookie(cname) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function useProvideAddress() {
  console.log('[auth] useProvideAddress');
  const [websocket, setWebsocket] = useState('');
  const [api, setApi] = useState('');
  const [localMode, setLocalMode] = useState(false);

  useEffect(() => {
    // Get websocket-address from .env or session storage if availible
    const s_storage_ws_address = sessionStorage.getItem('ws-address');
    if (websocket === '' && process.env.REACT_APP_WEBSOCKET_ADDRESS) {
      setWebsocket(process.env.REACT_APP_WEBSOCKET_ADDRESS);
    } else if (websocket === '' && s_storage_ws_address && s_storage_ws_address.length !== 0) {
      setWebsocket(s_storage_ws_address);
    }

    // Get api-address from session storage if availible
    const s_storage_api_address = sessionStorage.getItem('api-address');
    if (websocket === '' && s_storage_api_address && s_storage_api_address.length !== 0) {
      console.log('Set api address from sessionStorage: ', s_storage_api_address);
      setApi(s_storage_api_address);
    }
  }, []);

  /*
   * Sets ws- and api-address state and updates session storage.
   */
  const setupAddress = (ws_address, api_address) => {
    setWebsocket(ws_address);
    sessionStorage.setItem('ws-address', ws_address);
    setApi(api_address);
    sessionStorage.setItem('api-address', api_address);
  };

  const api_url = (url) => {
    if (process.env.REACT_APP_STATIC_USING_APP === 'true') {
      return api + url + (url.includes('?') ? '&' : '?') + 'credentials=' + getCookie('SESSID');
    } else {
      return url;
    }
  };

  return {
    websocket,
    api,
    setupAddress,
    api_url,
    localMode,
    setLocalMode
  };
}

export function ProvideAddress({ children }) {
  const address = useProvideAddress();
  return <addressContext.Provider value={address}>{children}</addressContext.Provider>;
}
