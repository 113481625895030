import { proxy, useSnapshot, ref } from 'valtio'
import { createWebsocketManager } from './websocket/WebsocketManager.js';
import { createSimulation } from './websocket/Simulation.js';
import { initialHistory } from './Constants/InitialSettings.js';

import _ from 'lodash';

//import {info_values, climate, co2, ventilation, timing, lights, system, multi_outs, alarm, advanced} from './Constants/settings.js'
function stateManager()
{
	return {
		controllers: [],
		simulation: null,
		
		alarms: {
			activeAlarms: [],
			alarmsLog: [],
			unreadAlarms: false,
			openAlarms: false
		},
		messages: {
			messages: [],
			unreadMessages: false,
		},
		
		init (wasm, address, hooks) {
			store.address = address.websocket;
			store.wasm = ref (wasm);
			 // If controller are unset but availible in session storage, get controller from there.
			 // why do we use session storage inseatd of localstorage?
			/*const session_storage_controllers = JSON.parse(sessionStorage.getItem('controllers'));
			if (
				store.controllers.length === 0 &&
				session_storage_controllers &&
				session_storage_controllers.length !== 0
			) {
				console.log('[StateManager] updates controllers', session_storage_controllers);
				store.controllers = session_storage_controllers;
			}*/
			
			store.hooks = hooks;
			store.wsManager = ref ( createWebsocketManager (wasm) );
			store.wsManager.init (store);
		},
		
		getControllerById: (id) => (
			store.controllers.find (c => c.id === id)
		),
		
		getControllerByName: (name) => (
			store.controllers.find (c => c.name === name)
		),
		
		
		addController: function (data, user) {
			let temp_settings = JSON.parse ( localStorage.getItem (`${data.id}_temp_settings`) ?? 'null' );
			
			let controller = {
				...data,
			
				//id: store.controllers.length,
				settings: {},
				temp_settings: temp_settings ? _.cloneDeep (temp_settings) : {}, // hold settings which are not saved yet
				history: _.cloneDeep (initialHistory),
				infos: { humidity_graph: [], temp_graph: [] },
				connection: {
					initialized: false,
					requested_all: false,
					request_values_full: false,
					status: 'bad',
					last_received: 0
				}
			};

			
			if (controller.simulation === true)
			{
				controller.simulation = createSimulation (store.address, controller, user, store.wasm);
				store.simulation = ref (controller.simulation);
			}
			
			store.controllers = store.controllers
									.filter ( c => c.id !== controller.id) // remove duplicates
									.concat (controller);
		},
		
		saveSettings (controller, newSettings, tab_id, transform=null)
		{
			if (newSettings !== null) {
				if (controller.connection.status !== 'bad') {
					controller.settings[tab_id] = newSettings;
					const settings_data = {};
					settings_data[tab_id] = transform ? transform (newSettings) : newSettings;
					store.wsManager.sendWSMsg (controller, 'update_setpoints', settings_data);
				}
			}
		},
		
		// return reference to the real store behind useSnapshot().
		// use this for manipulating states directly without a set-function
		store() {
			return store;
		}
	};
}

let store;

export default function useStateManager() {
	if (store == null) store = proxy ( stateManager() ); // makes proxy re-initializable, used for logout
	return useSnapshot (store);
}

export function destroyState()
{
	store = null;
}
