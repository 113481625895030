export const inital_data = {
  labels: [],
  datasets: [
    {
      label: 'humidity',
      data: [],
      hidden: false,
      tension: 0.5,
      fill: false,
      backgroundColor: 'rgb(42, 150, 220)',
      borderColor: 'rgba(42, 150, 220, 0.5)',
      unitPrefix: ' % RH'
    },
    {
      label: 'temp',
      data: [],
      hidden: false,
      tension: 0.5,
      fill: false,
      backgroundColor: 'rgb(250, 48, 20)',
      borderColor: 'rgba(250, 48, 20, 0.5)',
      unitPrefix: ' °C'
    },
    {
      label: 'co2',
      yAxisID: 'high',
      data: [],
      hidden: true,
      tension: 0.5,
      fill: false,
      backgroundColor: 'rgb(143,143,143)',
      borderColor: 'rgba(143,143,143, 0.5)',
      unitPrefix: ' ppm'
    },
    {
      label: 'vpd',
      data: [],
      hidden: true,
      tension: 0.5,
      fill: false,
      backgroundColor: 'rgb(94, 10, 255)',
      borderColor: 'rgba(94, 10, 255, 0.5)',
      unitPrefix: ' hPa'
    },
    {
      label: 'temp_leaf_current',
      data: [],
      hidden: true,
      tension: 0.5,
      fill: false,
      backgroundColor: 'rgb(149, 193, 30)',
      borderColor: 'rgba(149, 193, 30, 0.5)',
      unitPrefix: ' °C'
    }
  ]
};

export const resolution_mapping = {
  4: { name: 'history_type_recent', req: 'get_recent_history' },
  240: { name: 'history_type_daily', req: 'get_daily_history' },
  1800: { name: 'history_type_full', req: 'get_history' }
};
