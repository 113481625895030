import React, { Fragment } from 'react';
import { Switch, Route, useParams, useRouteMatch } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { CircularIndeterminate } from '../FormWidgets/Others';

import { useConfigObject } from './ControllerPage/contentConfig.js';
import { ControllerTabs } from './ControllerPage/ControllerTabs.js';

import useStateManager from '../StateManager.js';

export default function ControllerPage({ ...other }) {
  // Use the `useParams` hook here to access the dynamic pieces of the URL.
  let { path, url } = useRouteMatch();
  let { id } = useParams();

  // Get controller type.
  
  const stateManager = useStateManager();
  const controller = stateManager.getControllerById (id);
  const contType = controller?.type;
  const contVersion = controller?.api_version;
  const configObject = useConfigObject(
    contType,
    contVersion,
  );

  return (
    <Fragment>
      <Switch>
        <Route path={`${path}/:tab_id?`}>
          <ControllerTabs url={url} />
		  
		  { Object.keys ( controller?.settings ?? {} ).length >= 12 ?
			  configObject?.map((item) => (
				<Container key={'tab_' + item.id}>
					<ControllerTabPanel id={item.id}>{item.content}</ControllerTabPanel>
				</Container>
			  ))
			  :
			  <div style={{display: 'flex', justifyContent: 'center', marginTop: '3em'}}>
				<CircularIndeterminate />
			  </div>
		  }
        </Route>
      </Switch>
    </Fragment>
  );
}

function ControllerTabPanel({ id, children, ...other }) {
  let { tab_id } = useParams();
  if (id === 'info' && tab_id === undefined) tab_id = id;

  return (
    <div
      role="tabpanel"
      hidden={tab_id !== id}
      id={`wrapped-tabpanel-${id}`}
      aria-labelledby={`wrapped-tab-${id}`}
      {...other}
    >
      {tab_id === id && <Box style={{ marginTop: 50 }}>{children}</Box>}
    </div>
  );
}
