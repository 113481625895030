import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { MultiOutSelect } from '../FormWidgets/Select.js';

import { useTranslation } from 'react-i18next';
import { LoadSettingsDialog } from '../Utils/load_settings';

import { MultiOutHeadingEditable } from '../FormWidgets/MultiOutLabel';
import { SettingGroup, ValueBox } from '../FormWidgets/SettingGroup';

import { SettingActions } from '../components/index.js';

import useStateManager from '../StateManager.js';

import {
  settingControls,
  useSavedSettings,
} from './settingFunctions';

export default function MultiOutSettings({ contType, ...other }) {
  let { t } = useTranslation();
  let { id } = useParams();
  let sid = 'multi_out'; // tab id is "outputs"
  
  const stateManager = useStateManager();
  const controller = stateManager.getControllerById (id);
  const [values, setValues] = useState (controller.settings[sid]);
  const controls = settingControls (controller, sid, stateManager.saveSettings, values, setValues);
  const { openLoadSettingsDialog, closeLoadSettingsDialog } = useSavedSettings (controller, controls, sid);

  const saveMemo = (id, event, value) => {
	  console.log ('setMemo', id, event);
	  setValues ({...values, [id]: value});
	  controls.saveTempSettings (id, value);
  }
  
  const saveSelect = (index, event, value) =>
  {
	  let id = `out_${index + 1}_func`;
	  let v = event.target.value;
	  setValues ({...values, [id]: v})
      controls.saveTempSettings (id, v);
  };
  
  let multi_outs = [];
  for (let i = 1; i <= controller.settings.multi_out_values.out_count ?? 0; i++)
    multi_outs.push(['out_' + i + '_func', 'out_' + i + '_type', 'out_' + i + '_memo'] ?? '');
  
  return (
    <React.Fragment>
      <LoadSettingsDialog
        open={openLoadSettingsDialog}
        handleClose={closeLoadSettingsDialog}
        controls={controls}
      />

      <SettingActions
        onReset={controls.resetSettings}
        onSave={controls.saveSettings}
        
      />

      <SettingGroup label={t('multifunctional_outputs_heading')}>
        {multi_outs.map((multi_out_index, i) => (
          <ValueBox>
            <MultiOutHeadingEditable
              index={i + 1}
              memo={values[`out_${i + 1}_memo`] ?? ''}
              handleInput={saveMemo}
            />
            <MultiOutSelect
              value={values[`out_${i + 1}_func`] ?? ''}
              type={values[`out_${i + 1}_type`] ?? ''}
              name={multi_out_index[0]}
              not_pro={contType !== 5055}
              onChange={ (event, value) => saveSelect (i, event, value) }
            />
          </ValueBox>
        ))}
      </SettingGroup>

      <SettingActions
        onReset={controls.resetSettings}
        onSave={controls.saveSettings}
        
      />
    </React.Fragment>
  );
}
