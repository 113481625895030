import Info from '../../Tabs/Info.js';
import AdvancedSettings from '../../Tabs/AdvancedSettings.js';
import ClimateSettings from '../../Tabs/ClimateSettings.js';
import Co2Settings from '../../Tabs/Co2Settings.js';
import LightsSettings from '../../Tabs/LightsSettings.js';
import SystemSettings from '../../Tabs/SystemSettings.js';
import TimingSettings from '../../Tabs/TimingSettings.js';
import VentilationSettings from '../../Tabs/VentilationSettings.js';
import MultiOutSettings from '../../Tabs/MultiOutSettings.js';
import AlarmSettings from '../../Tabs/AlarmSettings.js';
import { MemorizedGraph } from '../../Tabs/Graph';

export const useConfigObject = (
  contType,
  contVersion,
) => [
  {
    id: 'info',
    content: <Info contType={contType} />
  },
  {
    id: 'graph',
    content: <MemorizedGraph contType={contType} />
  },
  {
    id: 'climate',
    content: (
      <ClimateSettings
        contType={contType}
      />
    )
  },
  {
    id: 'co2',
    content: <Co2Settings contType={contType} />
  },
  {
    id: 'ventilation',
    content: <VentilationSettings  />
  },
  {
    id: 'timing',
    content: <TimingSettings contType={contType} />
  },
  {
    id: 'lights',
    content: <LightsSettings  />
  },
  {
    id: 'system',
    content: <SystemSettings  contType={contType} contVersion={contVersion} />
  },
  {
    id: 'outputs',
    content: <MultiOutSettings  contType={contType} />
  },
  {
    id: 'alarms',
    content: <AlarmSettings  contType={contType} />
  },
  {
    id: 'advanced',
    content: <AdvancedSettings  contType={contType} />
  }
];
