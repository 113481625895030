import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { useTranslation } from 'react-i18next';

import { ShowSetting } from '../FormWidgets/ShowSliderSetting';
import Desc from '../FormWidgets/Description';
import {
  EnableDisableSelect,
  TempFormatSelect,
  DisplayTimeoutSelect,
  DateFormatSelect,
  LanguageSelect,
  AverageMinMaxSelect,
  FirmwareSelect,
  EcAcSelect
} from '../FormWidgets/Select';
import { HandleAction } from '../Utils/utils.js';
import { LoadSettingsDialog } from '../Utils/load_settings';
import { firmware_version_infos, statusColors } from '../Constants/constants';
import { useAuth } from '../Hooks/authHook';
import { SettingGroup, SettingGroupGrid, ValueBox } from '../FormWidgets/SettingGroup';
import {
  settingControls,
  useSavedSettings,
} from './settingFunctions';
import { SettingActions } from '../components';
import { useIsMobile } from '../Hooks/useMedia';
import { useAddress } from '../Hooks/addressHook';

import useStateManager from '../StateManager.js';

export default function SystemSettings({ contType, contVersion, ...other }) {
  const isMobile = useIsMobile();
  let { t } = useTranslation();
  let { id, tab_id } = useParams();
  
   const stateManager = useStateManager();
  const controller = stateManager.getControllerById (id);
  const [values, setValues] = useState (controller.settings[tab_id]);
  const controls = settingControls (controller, tab_id, stateManager.saveSettings, values, setValues);
  
  console.log ('CONTROLS',controls);
  const { openLoadSettingsDialog, closeLoadSettingsDialog } = useSavedSettings (controller, controls);

  // firmware updates.
  const address = useAddress();
  const auth = useAuth();
  const [firmware_versions, setFirmwareVersions] = React.useState({
    5055: [],
    5053: []
  });
  const [firmware_version, setFirmwareVersion] = React.useState(-1);
  useEffect(() => {
    console.log('Requested version numbers.');
    if (auth.user) {
      fetch(address.api_url('/api/versions')).then((response) => {
        if (response.ok) {
          response.text().then((text) => {
            const response = JSON.parse(text);
            setFirmwareVersions({ ...response['version_numbers'] });
          });
        }
      });
    }
  }, []);


  const callAction = (type, value) => {
    if (controller.connection.status !== 'bad') HandleAction (stateManager.wsManager, controller, type, value);
  };

  const callFirmwareAction = () => {
    const firmware_data = firmware_versions[contType][firmware_version];
    console.log('callFirmwareAction: ', firmware_version, firmware_data);
    if (firmware_data && controller.connection.status !== 'bad')
      stateManager.wsManager.sendWSMsg (controller, 'do_firmware_update', firmware_data);
  };


  // (Auto-) Load saved settings.

  const [open_wifi_dialog, setOpenWifiDialog] = React.useState(false);
  const openWifiDialog = () => {
    setOpenWifiDialog(true);
  };
  const closeWifiDialog = () => {
    setOpenWifiDialog(false);
  };

  const [open_confirm_fw_update_dialog, setOpenConfirmFWUpdateDialog] = React.useState(false);
  const openConfirmFwUpdateDialog = () => {
    setOpenConfirmFWUpdateDialog(true);
  };
  const closeConfirmFwDialogDialog = () => {
    setOpenConfirmFWUpdateDialog(false);
  };

  const [confirm_action_dialog_infos, setOpenConfirmActionDialog] = React.useState({
    open: false,
    type: '',
    value: undefined
  });
  const openConfirmActionDialog = (type, value) => {
    setOpenConfirmActionDialog({ open: true, type: type, value: value });
  };
  const closeConfirmActionDialog = () => {
    setOpenConfirmActionDialog({ open: false, type: '', value: -1 });
  };
  
  return (
    <React.Fragment>
      <WifiDialog open={open_wifi_dialog} handleClose={closeWifiDialog} stateManager={stateManager} controller={controller} />
      <ConfirmActionDialog
        open={confirm_action_dialog_infos.open}
        handleClose={closeConfirmActionDialog}
        controller={controller}
        action_type={confirm_action_dialog_infos.type}
        action_value={confirm_action_dialog_infos.value}
      />

      <ConfirmFirmwareUpdateDialog
        open={open_confirm_fw_update_dialog}
        handleClose={closeConfirmFwDialogDialog}
        handleFirwareUpdate={callFirmwareAction}
      />

      <LoadSettingsDialog
        open={openLoadSettingsDialog}
        handleClose={closeLoadSettingsDialog}
        controls={controls}
      />

      <SettingActions
        onReset={controls.resetSettings}
        onSave={controls.saveSettings}
        
      />

      <SettingGroup label={'formats'}>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="temp_format" name="Temperature Format" />
          </Typography>
          <TempFormatSelect
            value={values.temp_format}
            name="temp_format"
            onChange={(event, value) =>
              controls.setValue('temp_format', event.target.value)
            }
          />
        </ValueBox>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="date_format" name="Date Format" />
          </Typography>
          <DateFormatSelect
            value={values.date_format}
            name="date_format"
            onChange={(event, value) =>
              controls.setValue('date_format', event.target.value)
            }
          />
        </ValueBox>
      </SettingGroup>

      <SettingGroup label={'controller_menu_settings'}>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="language" name="Language" />
          </Typography>
          <LanguageSelect
            value={values.language}
            name="language"
            onChange={(event, value) =>
             controls.setValue('language', event.target.value)
            }
          />
        </ValueBox>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="display_timeout_gc" name="Display Timeout" />
          </Typography>
          <DisplayTimeoutSelect
            value={values.display_timeout}
            name="display_timeout"
            onChange={(event, value) =>
              controls.setValue('display_timeout', event.target.value)
            }
          />
        </ValueBox>
      </SettingGroup>

      <SettingGroup label={'menu_settings'}>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="fan_type_select" name="Display Timeout" />
          </Typography>
          <EcAcSelect
            value={values.fan_type_select}
            name="fan_type_select"
            onChange={(event, value) =>
              controls.setValue('fan_type_select', event.target.value)
            }
          />
        </ValueBox>
      </SettingGroup>

      {contType === 5055 && (
        <SettingGroup label={'multi_sensor_handling'}>
          <ValueBox>
            <Typography gutterBottom>{t('multi_sensor_handling_rh')}</Typography>
            <AverageMinMaxSelect
              value={values.multi_sensor_handling_rh}
              name="multi_sensor_handling_rh"
                onChange={(event, value) =>
                controls.setValue('multi_sensor_handling_rh', event.target.value)
              }
            />
          </ValueBox>
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="multi_sensor_handling_temp" name="Multi Sensor Handling Temperature" />
            </Typography>
            <AverageMinMaxSelect
              value={values.multi_sensor_handling_temp}
              name="multi_sensor_handling_temp"
                onChange={(event, value) =>
                controls.setValue('multi_sensor_handling_temp', event.target.value)
              }
            />
          </ValueBox>
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="multi_sensor_handling_co2" name="Multi Sensor Handling CO2" />
            </Typography>
            <AverageMinMaxSelect
              value={values.multi_sensor_handling_co2}
              name="multi_sensor_handling_co2"
                onChange={(event, value) =>
                controls.setValue('multi_sensor_handling_co2', event.target.value)
              }
            />
          </ValueBox>
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="multi_sensor_handling_pressure" name="Multi Sensor Handling Pressure" />
            </Typography>
            <AverageMinMaxSelect
              value={values.multi_sensor_handling_pressure}
              name="multi_sensor_handling_pressure"
                onChange={(event, value) =>
                controls.setValue('multi_sensor_handling_pressure', event.target.value)
              }
            />
          </ValueBox>
          <ValueBox>
            <Typography gutterBottom>
              <Desc
                id="multi_sensor_handling_heating_mat"
                name="Multi Sensor Handling Heating Mat"
              />
            </Typography>
            <AverageMinMaxSelect
              value={values.multi_sensor_handling_heating_mat}
              name="multi_sensor_handling_heating_mat"
                onChange={(event, value) =>
                controls.setValue('multi_sensor_handling_heating_mat', event.target.value)
              }
            />
          </ValueBox>
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="multi_sensor_handling_leaf" name="Multi Sensor Handling Leaf" />
            </Typography>
            <AverageMinMaxSelect
              value={values.multi_sensor_handling_leaf}
              name="multi_sensor_handling_leaf"
                onChange={(event, value) =>
                controls.setValue('multi_sensor_handling_leaf', event.target.value)
              }
            />
          </ValueBox>
        </SettingGroup>
      )}

      <SettingActions
        onReset={controls.resetSettings}
        onSave={controls.saveSettings}
        
      />

      <SettingGroupGrid label={t('sensor_calibration_hl')}>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="pressure_auto_cal" />
          </Typography>
          <EnableDisableSelect
            value={values.pressure_auto_cal}
            name="pressure_auto_cal"
            onChange={(event, value) =>
              controls.setValue('pressure_auto_cal', event.target.value)
            }
          />
        </ValueBox>
        <Button
          color="primary"
          onClick={(event) => callAction('action_calibrate_pressure_trigger')}
        >
          {t('calibrate_pressure_trigger')}
        </Button>

        <ShowSetting
          id="filter_val_sensor_co2"
          cur_value={values.filter_val_sensor_co2}
          controller_value={controller.settings.system.filter_val_sensor_co2}
          controls={controls}
        />
        <Button
          color="primary"
                    onClick={() =>
            callAction('action_transmit_filter_sensor', values.filter_val_sensor_co2)
          }
        >
          {t('transmit')}
        </Button>
        <ShowSetting
          id="calib_val_sensor_co2"
          cur_value={values.calib_val_sensor_co2}
          controller_value={controller.settings.system.calib_val_sensor_co2}
          controls={controls}
        />
        <Button
          color="primary"
                    onClick={() =>
            callAction(
              'action_transmit_calib_filter_sensor_co2',
              values.calib_val_sensor_co2
            )
          }
        >
          {t('transmit')}
        </Button>
        <Button
          onClick={() => openConfirmActionDialog('action_restore_factory_default', undefined)}
          color="secondary"
        >
          {t('resore_factory_default')}
        </Button>
      </SettingGroupGrid>

      <SettingGroup label={'firmware_hl'}>
        <ValueBox>
          <Typography
            gutterBottom
            style={isMobile ? { wordWrap: 'break-word', whiteSpace: 'pre-wrap' } : {}}
          >
            <strong>{t('current_firware_version_main_controller')}:</strong>
          </Typography>
          <label style={{ whiteSpace: 'pre-line' }}>
            <i>{values.fw_version_number}</i>
          </label>
        </ValueBox>
        <ValueBox>
          <Typography
            gutterBottom
            style={isMobile ? { wordWrap: 'break-word', whiteSpace: 'pre-wrap' } : {}}
          >
            <strong>{t('current_firware_version_wifi')}:</strong>
          </Typography>
          <label style={{ whiteSpace: 'pre-line' }}>
            <i>{values.esp32_firmware_name}</i>
          </label>
        </ValueBox>
        <ValueBox>
          <Typography gutterBottom>{t('firmware_update')}</Typography>
          <FirmwareSelect
            value={firmware_version}
            versions={firmware_versions[contType]}
            name="firmware_update"
            id="firmware_update"
            contApiVersion={contVersion}
            contFWVersion={controller.settings.system.esp32_firmware_name}
            onChange={(event, value) => setFirmwareVersion(event.target.value)}
          />
        </ValueBox>
        <div>
          <Button
            color="primary"
            disabled={firmware_version === -1}
            onClick={() => openConfirmFwUpdateDialog()}
          >
            {t('apply_firmware_update')}
          </Button>
          <br />
          {firmware_version_infos[contType].map((entry) => (
            <>
              <a
                href={entry.link}
                style={{ color: statusColors.good }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {entry.text} <PictureAsPdfIcon />
              </a>
              <br />
            </>
          ))}
        </div>
      </SettingGroup>

      <SettingGroup label={'wifi_options'}>
        <Button color="primary" onClick={openWifiDialog}>
          {t('change_wifi_options')}
        </Button>
      </SettingGroup>
    </React.Fragment>
  );
}

function ConfirmActionDialog({ open, handleClose, controller, action_type, action_value }) {
  let { t } = useTranslation();
  
  const stateManager = useStateManager();
  
  const doAction = (event) => {
    HandleAction (stateManager.wsManager, controller, action_type, action_value);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('confirm_action_title')}</DialogTitle>
      <DialogContent>{t(action_type + '_text')}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={doAction} color="primary">
          {t('apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ConfirmFirmwareUpdateDialog({ open, handleClose, handleFirwareUpdate }) {
  let { t } = useTranslation();

  const doAction = (event) => {
    handleFirwareUpdate();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('confirm_firmware_update_title')}</DialogTitle>
      <DialogContent>{t('confirm_firmware_update_text')}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={doAction} color="primary">
          {t('apply')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function WifiDialog({ open, handleClose, stateManager, controller }) {
  let { t } = useTranslation();

  const [name, setName] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [msg, setMsg] = React.useState('');

  const changeWifi = (event) => {
    let data = { password: password, name: name };
    if (password === null || name === null)
      setMsg('All fields (password and name) must be filled.');
    else if (data.password.length < 8) setMsg('Password must be at least 8 characters long');
    else if (data.name.length < 3) setMsg('Wifi name must be at least 3 characters long');
    else {
      
      stateManager.wsManager.sendWSMsg ( controller, 'set_ap_credentials', data );
      //setWSReq(CreateControllerWSRequest(wsRef.current, id, 'set_ap_credentials', data));
      handleClose();
      setMsg('');
    }
  };
  const resetWifi = (event) => {
    let data = { password: '', name: '', reset: true };
    stateManager.wsManager.sendWSMsg ( controller, 'set_ap_credentials', data );
    //setWSReq(CreateControllerWSRequest(wsRef.current, id, 'set_ap_credentials', data));
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('change_wifi_options')}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="serial"
          label={t('label_wifi_name')}
          type="text"
          fullWidth
          onChange={(event) => setName(event.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="serial"
          label={t('label_wifi_password')}
          type="password"
          fullWidth
          autoComplete="new-password"
          onChange={(event) => setPassword(event.target.value)}
        />
        {msg}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={changeWifi} color="primary">
          {t('apply')}
        </Button>
        <Button title={t('reset_to_factory_default')} onClick={resetWifi} color="primary">
          {t('reset')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
