import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';

import { multiOutMapping } from '../Constants/constants';

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.main
  }
}));

export default function MultiOutLabel({ func, value, ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  let value_field = '';
  if (func === 0 || func === 1) value_field = value + ' %';
  else if (value === 0) value_field = ' ' + t('off');
  else if (value > 0) value_field = ' ' + t('on');

  if (func === 19) {
    return <label className={classes.label}>{t(multiOutMapping[func])}</label>;
  } else {
    return (
      <label className={classes.label}>
        {t(multiOutMapping[func])}: {value_field}
      </label>
    );
  }
}

function MultiOutContent({ index, memo }) {
  let { t } = useTranslation();
  return (
    <React.Fragment>
      {t('multifunctional_out')} {index}
      <br />
      {memo !== -1 && memo !== '' ? '(' + memo + ')' : <br />}
    </React.Fragment>
  );
}
export function MultiOutHeading({ index, memo, content }) {
  return (
    <Typography gutterBottom>
      <MultiOutContent index={index} memo={memo} />
    </Typography>
  );
}

export function MultiOutHeadingEditable({ index, memo, handleInput }) {
  let { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const openDialog = () => {
    setOpen(true);
  };
  const closeDialog = () => {
    setOpen(false);
  };

  const show_memo = memo !== -1 && memo !== '' ? memo : t('custom_memo_name');

  return (
    <React.Fragment>
      <Typography gutterBottom>
        <MultiOutContent index={index} memo={show_memo} />
        <EditIcon style={{ cursor: 'pointer' }} onClick={openDialog} />
      </Typography>

      <MemoDialog
        open={open}
        handleClose={closeDialog}
        name={'out_' + index + '_memo'}
        curMemo={memo !== -1 && memo !== '' ? memo : ''}
        handleInput={handleInput}
      />
    </React.Fragment>
  );
}

function ValidateMemoName(memo_name) {
  if (memo_name.length === 0) return '';
  if (memo_name.length > 20) return 'memo_name_max_chars_exeeded';
  if (!memo_name.match(/^[-\-+\-&\-/\-0-9\-a-z\-A-Z(). ]+$/g))
    return 'memo_name_invalid_characters';
  return '';
}

function MemoDialog({ open, handleClose, name, curMemo, handleInput }) {
  let { t } = useTranslation();
  const [memo, setMemo] = React.useState({ value: curMemo });
  const [error, setError] = React.useState('');

  const changeMemo = (event) => {
    const new_error = ValidateMemoName(memo.value);
    if (new_error === '') {
      handleInput(name, event, memo.value);
      setError('');
      handleClose();
    } else setError(t(new_error));
  };

  const deleteMemo = (event) => {
    setMemo({ value: '' });
    handleInput(name, event, -1);
    setError('');
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('change_memo')}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="serial"
          label="Memo"
          type="text"
          fullWidth
          placeholder={t('custom_memo_name')}
          value={memo.value}
          onChange={(event) => setMemo({ ...memo, value: event.target.value })}
        />
        {error}
      </DialogContent>
      <DialogActions>
        <Button onClick={changeMemo} color="primary">
          {t('set')}
        </Button>
        <Button onClick={deleteMemo} color="primary">
          {t('delete')}
        </Button>
        <Button onClick={handleClose} color="primary">
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
