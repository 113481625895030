import { ReactComponent as Logo } from './Images/logo.svg';

import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';

import React, { Fragment, useEffect } from 'react';
import { Redirect, Switch, Route, useParams } from 'react-router-dom';

import CookieConsent from 'react-cookie-consent';

import { AppBar, IconButton, Typography, Toolbar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import MuiAlert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import { wasm } from './Utils/utils.js';

import { useTranslation } from 'react-i18next';

import i18next from 'i18next';

import { Helmet } from 'react-helmet';

import MainMenu from './Menus/MainMenu';
import UserMenu from './Menus/UserMenu';
import ControllerPage from './Pages/ControllerPage';
import ManagePage, { DeleteDialog } from './Pages/ManagePage';
import AccountPage from './Pages/Account';
import LoginPage from './Pages/LoginPage';
import ResetPage from './Pages/ResetPage';
import RegistrationPage from './Pages/RegistrationPage.js';
import SetupPage from './Pages/SetupPage';
import { useAuth } from './Hooks/authHook';
import { useAddress } from './Hooks/addressHook';
import AlarmModal from './Contents/Alarms';
import MessagesModal from './Contents/Messages';

import { CreateSimulationInit } from './Utils/simulation_utils.js';

import { statusColors } from './Constants/constants';

import { LockSliderContext } from './Contexts';

import { AlarmSelect } from './FormWidgets/Select';
import Description from './FormWidgets/Description';
import { useSnackbar } from 'notistack';

import useStateManager from './StateManager.js';

const routes = [
  {
    path: '/',
    exact: true,
    title: 'manage_controllers',
    main: () => <ManagePage />,
    private: true
  },
  {
    path: '/controller/:id',
    title: () => <ControllerName />,
    main: () => <ControllerPage />,
    private: true
  },
  {
    path: '/account',
    title: 'account_settings',
    main: ({ user, setUser }) => <AccountPage user={user} setUser={setUser} />,
    private: true
  },
  {
    path: '/login',
    title: 'login',
    main: () => <LoginPage />,
    private: false
  },
  {
    path: '/register',
    title: 'register',
    main: () => <RegistrationPage />,
    private: false
  },
  {
    path: '/reset',
    title: 'password_reset',
    main: () => <ResetPage />,
    private: false
  },
  {
    path: '/setup',
    title: 'mode_setup',
    main: () => <SetupPage />,
    private: false
  }
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    zIndex: 2
  },
  black: {
    // backgroundColor: theme.palette.text.primary
    backgroundColor: theme.palette.appbar_bg
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      '& #text': {
        display: 'none'
      }
    }
  }
}));

export function App({ currTheme, toggleTheme }) {
  let { t } = useTranslation();
  const address = useAddress();
  console.log ('address 1', JSON.stringify (address));
  const auth = useAuth();

  // User
  const [user, setUser] = React.useState({});

  // Delete controller
  const [open_delete_dialog, setOpenDeleteDialog] = React.useState(false);
  const [cur_controller_id, setCurControllerId] = React.useState('');

  // Snackbar
  const [open, setOpen] = React.useState(false);
  const { messageInfo, setMessageInfo } = React.useState(undefined);
  const handleExited = () => {
    setMessageInfo(undefined);
  };
  let { enqueueSnackbar } = useSnackbar();

  // language
  const [lang, setLang] = React.useState('de');

  // Close dialogs
  const handleClose = (_, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  // Genral state.
  const [state, setState] = React.useState({
    showMainMenu: false,
    showUserMenu: false,
    userMenuAnchor: null
  });
  
  // ----- set-up global connection. ----- //
  

  const [no_connection, setNoConnection] = React.useState(false);
  const [reconnecting, setReconnecting] = React.useState(false);
  
  // stateManager, everything in here is controller related (for now)
  const stateManager = useStateManager();

  React.useEffect(() => {
    if (auth.user) {
	  wasm.then ( wasm => {					// pass non-valtio native react hooks
		  stateManager.init (wasm, address, {enqueueSnackbar: enqueueSnackbar,
											 setOpenDeleteDialog: setOpenDeleteDialog,
											 setNoConnection: setNoConnection,
											 setReconnecting: setReconnecting,
											 t: t});
		  
			
			
		  console.log('requesting data: ', address.api_url('/api/data'));
		  fetch(address.api_url('/api/data'))
			.then((response) => {
			  if (response.ok) {
				response.text().then((text) => {
				  const json = JSON.parse(text);
				  
				  // controller:
				  const received_controllers = json['controller'];
				  json['controller'].forEach ( c => stateManager.addController (c, auth.user) );
				  console.log ('added controller', json['controller']);
				  // language:
				  const account_language = json['language'];
				  setLang(account_language);
				  // email:
				  setUser({ name: auth.user, email: json['email'], language: account_language });
				  // alarms:
				  stateManager.store().activeAlarms = json['alarms'].map ( a => { a['controller'] = stateManager.getControllerByName (a['cont_name']); return a; });
				});
			  }
			})
			.catch((error) => enqueueSnackbar(error, { variant: 'error' }));
	  });
	}
  }, [auth.user]);
  

  // Set language
  useEffect(() => {
    // If no local language is set, update local language to account-language.
    const account_language = user.language;
    let session_language = localStorage.getItem('i18nextLng') || account_language || 'de';
    if (session_language === 'undefined' || session_language === 'null')
      session_language = account_language;
    // Update local-storage and current session-language with the set language.
    i18next.changeLanguage(session_language);
    setLang(session_language);
  }, [user]);

  const toggleMainMenu = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
    setState({ ...state, showMainMenu: open });
  };

  const toggleUserMenu = (open) => (event) => {
    event.stopPropagation();
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
    setState({ ...state, showUserMenu: open, userMenuAnchor: event.currentTarget });
  };

  const closeNoConnectionInfoDialog = () => {
    setOpen(false);
  };

  const [open_developer_tools, setOpenDeveloperTools] = React.useState(false);
  const closeDeveloperOptions = () => {
    setOpenDeveloperTools(false);
  };
  const openDeveleloperOptions = React.useCallback((event) => {
    if (event.keyCode === 115) {
      // f4
      setOpenDeveloperTools(true);
    }
  }, []);
  useEffect(() => {
    document.addEventListener('keydown', openDeveleloperOptions, false);
    return () => {
      document.removeEventListener('keydown', openDeveleloperOptions, false);
    };
  }, []);

  const [lockSliderSetting, setLockSliderSetting] = React.useState(
    localStorage.getItem('lockSliderSetting') || 'auto'
  );
  useEffect(() => {
    localStorage.setItem('lockSliderSetting', lockSliderSetting);
  }, [lockSliderSetting]);

  const classes = useStyles();
  // const isXS = useMediaQuery((theme) => theme.breakpoints.down('sm')); TODO (fux): is this needed?
  return (
    <Fragment>
      <Helmet htmlAttributes={{ lang: lang }}>
        <meta http-equiv="content-language" content={lang} />
      </Helmet>

      <AppBar className={classes.black} position="static" color="secondary">
        <Toolbar>
          <IconButton edge="start" onClick={toggleMainMenu(true)} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Fragment>
            <Logo className={classes.logo} height={16} fill="#ffffff" />
            <PageTitle stateManager={stateManager} />
            <MessagesModal logged_in={auth.user !== null} wsManager={stateManager.wsManager} />
            <AlarmModal logged_in={auth.user !== null} />
          </Fragment>
        </Toolbar>
      </AppBar>
      <NoConnectionInfoDialog open={no_connection} handleClose={closeNoConnectionInfoDialog} />
      {/* <ReconnectingInfoDialog open={reconnecting} handleClose={() => setReconnecting (false)} /> */}
      <DeveloperTools
        open={open_developer_tools}
        handleClose={closeDeveloperOptions}
      />
      <DeleteDialog
        open={open_delete_dialog}
        id={cur_controller_id}
        controllers={stateManager.controllers}
        wsManager={stateManager.wsManager}
        handleClose={() => {
          setCurControllerId('');
          setOpenDeleteDialog(false);
        }}
        command="delete_controller_force"
      />

      <MainMenu
        open={state.showMainMenu}
        onClose={toggleMainMenu(false)}
        currTheme={currTheme}
        toggleTheme={toggleTheme}
        toggleUserMenu={toggleUserMenu}
        setLang={setLang}
        lockSliderSetting={lockSliderSetting}
        setLockSliderSetting={setLockSliderSetting}
      />
      <UserMenu
        open={state.showUserMenu}
        onClose={toggleUserMenu(false)}
        anchor={state.userMenuAnchor}
      />

      <LockSliderContext.Provider value={lockSliderSetting}>
        <MainContent user={user} setUser={setUser} wsManager={stateManager.wsManager} />
      </LockSliderContext.Provider>

      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        onExited={handleExited}
        action={
          <React.Fragment>
            <IconButton aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      >
        <Alert onClose={handleClose} severity={messageInfo ? messageInfo.type : undefined}>
          {messageInfo ? messageInfo.message : undefined}
        </Alert>
      </Snackbar>
      <CookieConsent buttonText={t('cookie_consent_button')}>{t('cookie_consent')}</CookieConsent>
      <div style={{ display: 'flex', justifyContent: 'right', margin: '20px' }}>
        <Description id="pre_release_notice" />
      </div>
    </Fragment>
  );
}

function NoConnectionInfoDialog({ open, handleClose }) {
  let { t } = useTranslation();
  const style = {
    marginBottom: 20
  };
  const refresh = () => {
    window.location.reload();
  };
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('no_server_connection_msg_title')}</DialogTitle>
      <DialogContent style={style}>{t('no_server_connection_msg_body')}</DialogContent>
      <DialogActions>
        <Button onClick={refresh} color="primary">
          {t('refresh')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/*
 * Not used yet, maybe for later

function  ReconnectingInfoDialog({ open, handleClose }) {
  let { t } = useTranslation();
  const style = {
    marginBottom: 20
  };
  const refresh = () => {
    window.location.reload();
  };
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('no_server_connection_msg_title')}</DialogTitle>
      
      <div style={{display: "flex", padding: '2em'}}>
		<CircularIndeterminate />
		<DialogContent style={style}>{t('reconnecting_connection_msg_body')}</DialogContent>
	  </div>
    </Dialog>
  );
}*/

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ControllerName({ ...props }) {
  let { id } = useParams();
  const stateManager = useStateManager();
  
  const controller = stateManager.getControllerById (id);
  //if (controller === undefined) window.location = '/';
  
  const cont_status = controller?.connection?.status ?? 'bad';
  const cont_name = controller?.simulation ? controller.name + ' (simulation)' : controller?.name ?? '';

  return (
    <Fragment>
      <CircleIcon style={{ color: statusColors[cont_status] }} /> {cont_name}
    </Fragment>
  )
}

function PageTitle({ stateManager, ...props }) {
  let { t } = useTranslation();
  const classes = useStyles();
  
  
  return (
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          children={
            <Typography className={classes.title} variant="h6">
              {typeof route.title === 'function' ? (
                <route.title controllers={stateManager.controllers} />
              ) : (
                t(route.title)
              )}
            </Typography>
          }
        />
      ))}
    </Switch>
  );
}

function MainContent({ user, setUser, ...other }) {
  const address = useAddress();
  console.log ('address 0', JSON.stringify (address));
  const auth = useAuth();

  //console.log('cur apiAddress: ', address.api, ', cur wsAddress', address.websocket);
  // console.log("cur user: ", auth.user);

  return (
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          children={({ location }) =>
            !route.private || auth.user ? (
              <route.main user={user} setUser={setUser} />
            ) : process.env.REACT_APP_STATIC_USING_APP === 'true' && address.api === '' ? (
              <Redirect to={{ pathname: '/setup', state: { from: location } }} />
            ) : (
              <Redirect to={{ pathname: '/login', state: { from: location } }} />
            )
          }
        />
      ))}
    </Switch>
  );
}

function DeveloperTools({ open, handleClose, ...props }) {
  const [alarm, setAlarm] = React.useState({ name: 'all', value: 90 });
  const simulation = useStateManager().simulation;
  
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">Developer Tools</DialogTitle>
      <DialogContent>
        Test alarms:
        <AlarmSelect
          label="alarm-name"
          value={alarm.name}
          onChange={(event) => setAlarm({ ...alarm, name: event.target.value })}
        ></AlarmSelect>
        <TextField
          autoFocus
          margin="dense"
          id="serial"
          label="Value"
          type="number"
          fullWidth
          value={alarm.value}
          onChange={(event) => setAlarm({ ...alarm, value: event.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={ (evt) => simulation.send_alarm (evt, alarm) } color="primary">
          Trigger Alarm
        </Button>
        <Button onClick={ (evt) => simulation.resolve_alarm (evt, alarm) } color="primary">
          Resolve Alarm
        </Button>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
