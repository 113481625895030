import {
  CreateControllerWSRequest,
  CreateGlobalWSRequest,
  BinaryToUintArray,
  setImmediatelyInterval
} from '../Utils/utils.js';

import globalOnMessage from './globalOnMessageHook.js';


export function createWebsocketManager (wasm) {
	return {
		socket: null,
		status: 'off',
		wasm: wasm,
		
		init (store=null)
		{	
			if (store) this.store = store;
			this.address = store.address;
			
			this.socket = new WebSocket (this.address);
			this.socket.binaryType = "arrayBuffer";
			
			this.socket.onopen = () => {
				this.sendWSMsg (null, 'initialize_user');
				
				
				// in case of failed initialization the server does not respond at all.
				// catch this case here
				setTimeout ( () => {
					if (this.status === 'off')
					{
						console.log ('[wsManager] failed init');
						this.fail();
					}
				}, 3000); // 3 sec to wait for an answer
			};

			this.socket.onclose = () => {
				
				if (this.status === 'on') {
					console.log ('[wsManager] socket closed unexpected');
					this.fail();
					
				}
			};
			
			this.socket.onerror = () => {
				console.log ('[wsManager] socket error');
				this.fail();
			}
			
			this.socket.onmessage = (event) => {
				// convert binary blob to JSON
				try {
					const reader = new FileReader();
					reader.onload = () => {
						const binary_str = reader.result;
						const len = binary_str.length;
						const new_data = wasm.fromBinary(BinaryToUintArray(binary_str, len), len);
						
						const data = JSON.parse (new_data)
						console.log ('[wsManager] onmessage', data);
						globalOnMessage (this, data);
					};
					reader.readAsBinaryString(event.data);
				} catch (e) {
					console.log('[global] Failed parsing json: ', event.data, e);
				}
			};
			
		},
		
		// setup request loops
		start: function() {
			this.controllers_active = setImmediatelyInterval ( () =>
				this.sendWSMsg (null, 'controllers_active'),
			2500 );
			
			this.request_values = setImmediatelyInterval ( () => {
				const controllers = this.store.controllers;
				for (let controller of controllers)
				{
					if ( ( controller?.connection?.status ?? 'bad' ) !== 'bad')
					{
						if ( !controller.connection.request_values_full)
						{
							this.sendWSMsg (controller, 'request_values_full');
						}
						else
						{
							this.sendWSMsg (controller, 'request_values');
						}
					}
				}
			}, 1000);
		},
		
		sendWSMsg: function (controller, command, data={})
		{	
			//console.log ('sendWSMsg', controller);
			if (this.socket.readyState !== 1 || controller?.connection?.status === 'bad') return;
			
			const req = controller ? CreateControllerWSRequest (this.socket, controller.id, command, data) : CreateGlobalWSRequest (this.socket, command, data);
			
			if (req && this.wasm.toBinary) {
				// Add cookie
				req.cookie = document.cookie;
				// Get binary string through webassembly
				const json_str = JSON.stringify(req);
				//console.log ('init send', json_str);
				const binary_str = this.wasm.toBinary(json_str);
				let binaryData = new Uint8Array(
					atob(binary_str)
					.split('')
					.map(function (c) {
						return c.charCodeAt(0);
					})
				);
				
				// Convert to unit8 array
				// Finally send data
				this.socket.send (binaryData);
				if (req.command !== 'request_values') console.log('[ws send]', req.command + ':', json_str);
			}
		},
		
		stop: function ()
		{
			clearInterval (this.controllers_active);
			clearInterval (this.request_values);
			this.status = 'off';
			this.socket.close();
		},
		
		fail: function() {
			this.store.hooks.setNoConnection (true);
			this.stop();
		},
	};
}
