export const tabs = [
  { id: 'graph', label: 'Graph' },
  { id: 'climate', label: 'Climate' },
  { id: 'co2', label: 'C02' },
  { id: 'ventilation', label: 'Ventilation' },
  { id: 'timing', label: 'Timing' },
  { id: 'lights', label: 'Lights' },
  { id: 'system', label: 'System' },
  { id: 'outputs', label: 'Outputs' },
  { id: 'alarms', label: 'Alarms' },
  { id: 'advanced', label: 'Advanced' }
];
