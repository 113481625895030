import React from 'react';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import { GetHelp, DisplayName, InfoButton } from '../FormWidgets/Description';
import ReplayIcon from '@material-ui/icons/Replay';
import { MySlider } from '../FormWidgets/MySlider';
import { settings_borders_and_units } from '../Constants/constants';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import MuiSwitch from '@material-ui/core/Switch';

import { ValueBox } from '../FormWidgets/SettingGroup';

export function ShowSetting({
  id,
  show,
  cur_value,
  controller_value,
  controller,
  cat,
  disabled,
  min,
  max,
  unit,
  off_at,
  type,
  contType,
  controls,
  children
}) {
  const help = GetHelp(id, show, contType);

  if (min === undefined && id in settings_borders_and_units)
    min = settings_borders_and_units[id][0];
  if (max === undefined && id in settings_borders_and_units)
    max = settings_borders_and_units[id][1];
  
  let mode = 'single';
  let cur_value_min;
  let cur_value_max;
  let controller_value_min;
  let controller_value_max;
  let id_max;
  let id_min;
  let changed;
  if ( Array.isArray (cur_value) ) // double handle
  {
	  mode = 'double';
	  cur_value_min = cur_value[0];
	  cur_value_max = cur_value[1];
	  controller_value_min = controller_value[0];
	  controller_value_max = controller_value[1];
	  changed = {}; // check do we need this?
	  
	  id_max = id;
	  id_min = id.replace ('max', 'min');
	  
	  console.log ('max min',  id_max, id_min);
  }
  
  const adjusted_value =
    type === 'float'
      ? Number.parseFloat(cur_value) / 10
      : type === 'negative'
        ? cur_value - 127
        : cur_value;
  
  
  const hasChanged = mode == 'single' ? !(cur_value === controller_value) : (cur_value_min !== controller_value_min) || (cur_value_max !== controller_value_max);
  const convert = (v) => type === 'float' ? v * 10 : type === 'negative' ? v + 127 : v				



  return (
    <React.Fragment>
      <ValueBox>
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'no-wrap' }}>
            <Box alignItems={'center'}>
              <Typography gutterBottom style={{ whiteSpace: 'pre-wrap' }}>
                <DisplayName id={id} show={show} brief={help.brief} />
                <InfoButton content={help.content} img={help.img} />
              </Typography>
            </Box>
            <Box justify="flex-end">
              <Typography gutterBottom>
				{mode == 'single' ?
						<ShowCurValue name={id} value={cur_value} unit={unit} type={type} off_at={off_at} />
						:
						<>
							<ShowCurValue
							  name={id_min}
							  value={cur_value_min}
							  unit={unit}
							  type={type}
							  off_at={off_at}
							/>
							/&nbsp;
							<ShowCurValue
							  name={id_max}
							  value={cur_value_max}
							  unit={unit}
							  type={type}
							  off_at={off_at}
							/>
						</>
				}
						
                <IconButton onClick={() => mode == 'single' ? controls.resetSetting (id) : controls.resetMinMaxSlider (id_min, id_max)}>
                  <ReplayIcon fontSize="small" />
                </IconButton>
                
                {
					children
				}
              </Typography>
            </Box>
          </Box>
          <MySlider
            id={id}
            value={mode == 'single' ? cur_value : [cur_value_min, cur_value_max]}
            name={id}
            disabled={disabled}
            DecClick={() => {
				if (mode == 'single')
				{	
				  if (adjusted_value > min) {
						controls.setValue (id, cur_value - 1);
					}
				}
				else
				{
					 if (changed[id_max]) {
					  if (cur_value_max > min) controls.setValue(id_max, cur_value_max - 1);
					} else {
					  if (cur_value_min > min) controls.setValue(id_min, cur_value_min - 1);
					}
				}
            }}
            IncClick={() => {
				if (mode == 'single')
				{	
					if (adjusted_value < max) {
						controls.setValue (id, cur_value + 1);
					}
				}
				else { // mode == 'double'
					 if (changed[id_max]) {
					  if (cur_value_max > min) controls.setValue (id_max, cur_value_max + 1);
					} else {
					  if (cur_value_min > min) controls.setValue(id_min, cur_value_min + 1);
					}
				}
            }}
            onChange={(event, value) => {
			  if (mode == 'single') controls.setValue (id, convert (value), false);
			  else controls.setValueMinMaxSlider (id_min, id_max, convert (value[0]), convert (value[1]), false);
            }}
            
            // slider released
            onChangeCommitted={ (event, value) => {
				if (mode == 'single') {
					controls.setValue (id, convert (value));
					if (controls.onChangeCommitted)
					{
						controls.onChangeCommitted (id, convert (value) );
					}
				}
			    else controls.setValueMinMaxSlider (id_min, id_max, convert (value[0]), convert (value[1]));
			}}
				
			
            min={min}
            max={max}
            unit={unit}
            type={type}
            color={hasChanged ? 'secondary' : 'primary'}
          />
        </Box>
      </ValueBox>
    </React.Fragment>
  );
}


export function ShowSettingOnOff({
  controls,
  switchValue,
  switchId,
  ...other
}) {
	return (
		<ShowSetting controls={controls} {...other}>
			<MuiSwitch
                  checked={switchValue === 1}
                  onClick={(event) => controls.setValue (switchId, event.target.checked ? 1 : 0)}
                />
		</ShowSetting>
	)
}
	
	

export function ShowSettingMinMax({
  cur_value_min,
  controller_value_min,
  cur_value_max,
  controller_value_max,
  ...other})
{	
	return (
		<ShowSetting cur_value={[cur_value_min, cur_value_max]}
					 controller_value={[controller_value_min, controller_value_max]}
					 {...other} />
	)
}


function ShowCurValue({ text, name, unit, value, type, off_at, ...other }) {
  let { t } = useTranslation();

  // Get unit and borders
  if (unit === undefined && name in settings_borders_and_units)
    unit = settings_borders_and_units[name][2];

  // convert matching type
  if (type === 'float') {
    if (off_at !== undefined && value <= off_at) return <href>{t('off')}</href>;
    value = Number.parseFloat(value / 10).toFixed(1);
  } else if (type === 'negative') value = value - 127;
  else if (type === 'fan_min_max') {
    off_at = off_at === undefined ? 9 : off_at;
    if (value <= off_at) return <href>{t('off')}</href>;
  } else if (off_at !== undefined) {
    if (value <= off_at) return <href>{t('off')}</href>;
  }
  return (
    <href>
      {value}
      {unit}
    </href>
  );
}
