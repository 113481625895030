import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { ShowSetting, ShowSettingMinMax } from '../FormWidgets/ShowSliderSetting';
import { LoadSettingsDialog } from '../Utils/load_settings';
import { SettingGroupGrid } from '../FormWidgets/SettingGroup';

import {
  settingControls,
  useSavedSettings,
} from './settingFunctions';
import { SettingActions } from '../components';
import { useSnackbar } from 'notistack';

import useStateManager from '../StateManager.js';

export default function VentilationSettings({ ...other }) {
  let { t } = useTranslation();
  let { id, tab_id } = useParams();
  let { enqueueSnackbar } = useSnackbar();

  const stateManager = useStateManager();
  const controller = stateManager.getControllerById (id);
  const [values, setValues] = useState (controller.settings[tab_id]);
  const controls = settingControls (controller, tab_id, stateManager.saveSettings, values, setValues);
  controls.onChangeCommitted = function (id, v, saveTemp=true) {
	  let currentSettings = {...values};
	  // Adjust value affected by pressure_percent_day/ night (only ventilation)
	  if (
		(id === 'pressure_percent_day' || id === 'pressure_percent_night') &&
		currentSettings[id] < 127
	  ) {
		const key_ec = 'exhaust_fan_ec_max_' + id.split('_')[2]; // get day/ night component.
		const key_ac = 'exhaust_fan_ac_max_' + id.split('_')[2]; // get day/ night component.
		const val_ec = 100 + currentSettings[id] - 127;
		const val_ac = 100 + currentSettings[id] - 127;
		let msg = '';
		let c = false;
		if (currentSettings[key_ec] > val_ec) {
		  msg += t(key_ec);
		  currentSettings[key_ec] = val_ec;
		  c = true;
		}
		if (currentSettings[key_ac] > val_ac) {
		  currentSettings[key_ac] = val_ac;
		  msg += (msg !== '' ? ', ' : '') + t(key_ac);
		  c = true;
		}
		// If changes made: print message
		if (c) {
		  enqueueSnackbar (msg + ' ' + t('adjusted'), { variant: 'info' });
		}
	  }
	  
	  setValues ({...currentSettings});
	  // turn this off in slider's onChange to improve performance
	  if (saveTemp) this.saveTempSettings (id, v);
  };
  
  const { openLoadSettingsDialog, closeLoadSettingsDialog } = useSavedSettings (controller, controls);
  
  return (
    <React.Fragment>
      <LoadSettingsDialog
        open={openLoadSettingsDialog}
        handleClose={closeLoadSettingsDialog}
        controls={controls}
      />

      <SettingActions
        onReset={controls.resetSettings}
        onSave={controls.saveSettings}
      />

      <SettingGroupGrid label={t('fans_day')}>
        {[-1, 0, 1].indexOf(controller.settings.system.fan_type_select) > -1 && (
          <ShowSettingMinMax
            id="exhaust_fan_ec_max_day"
            show="exhaust_fan_ec_min_max_day"
            
            cur_value_max={values.exhaust_fan_ec_max_day}
            controller_value_max={controller.settings.ventilation.exhaust_fan_ec_max_day}
            cur_value_min={values.exhaust_fan_ec_min_day}
            controller_value_min={controller.settings.ventilation.exhaust_fan_ec_min_day}
            min={9}
            max={
              values.pressure_percent_day - 127 < 0
                ? 100 + (values.pressure_percent_day - 127)
                : 100
            }
            controls={controls}
            type="fan_min_max"
          />
        )}
        {[-1, 0, 1].indexOf(controller.settings.system.fan_type_select) > -1 && (
          <ShowSettingMinMax
            id="intake_fan_ec_max_day"
            show="intake_fan_ec_min_max_day"
            
            cur_value_max={values.intake_fan_ec_max_day}
            controller_value_max={controller.settings.ventilation.intake_fan_ec_max_day}
            cur_value_min={values.intake_fan_ec_min_day}
            controller_value_min={controller.settings.ventilation.intake_fan_ec_min_day}
            min={9}
            max={100}
            controls={controls}
            type="fan_min_max"
          />
        )}
        {[-1, 0, 2].indexOf(controller.settings.system.fan_type_select) > -1 && (
          <ShowSettingMinMax
            id="exhaust_fan_ac_max_day"
            show="exhaust_fan_ac_min_max_day"
            
            cur_value_max={values.exhaust_fan_ac_max_day}
            controller_value_max={controller.settings.ventilation.exhaust_fan_ac_max_day}
            cur_value_min={values.exhaust_fan_ac_min_day}
            controller_value_min={controller.settings.ventilation.exhaust_fan_ac_min_day}
            min={controller.settings.advanced.fan_ac_exhaust_turnoff - 1}
            max={
              values.pressure_percent_day - 127 < 0
                ? 100 + (values.pressure_percent_day - 127)
                : 100
            }
            off_at={controller.settings.advanced.fan_ac_exhaust_turnoff - 1}
            controls={controls}
            type="fan_min_max"
          />
        )}
        {[-1, 0, 2].indexOf(controller.settings.system.fan_type_select) > -1 && (
          <ShowSettingMinMax
            id="intake_fan_ac_max_day"
            show="intake_fan_ac_min_max_day"
            
            cur_value_max={values.intake_fan_ac_max_day}
            controller_value_max={controller.settings.ventilation.intake_fan_ac_max_day}
            cur_value_min={values.intake_fan_ac_min_day}
            controller_value_min={controller.settings.ventilation.intake_fan_ac_min_day}
            min={controller.settings.advanced.fan_ac_intake_turnoff - 1}
            max={100}
            off_at={controller.settings.advanced.fan_ac_intake_turnoff - 1}
            controls={controls}
            type="fan_min_max"
          />
        )}
        <ShowSetting
          id="pressure_percent_day"
          cur_value={values.pressure_percent_day}
          controller_value={controller.settings.ventilation.pressure_percent_day}
          controls={controls}
          
          
          type="negative"
        />
      </SettingGroupGrid>

      <SettingGroupGrid label={t('fans_night')}>
        {[-1, 0, 1].indexOf(controller.settings.system.fan_type_select) > -1 && (
          <ShowSettingMinMax
            id="exhaust_fan_ec_max_night"
            show="exhaust_fan_ec_min_max_night"
            
            cur_value_max={values.exhaust_fan_ec_max_night}
            controller_value_max={controller.settings.ventilation.exhaust_fan_ec_max_night}
            cur_value_min={values.exhaust_fan_ec_min_night}
            controller_value_min={controller.settings.ventilation.exhaust_fan_ec_min_night}
            min={9}
            max={
              values.pressure_percent_night - 127 < 0
                ? 100 + (values.pressure_percent_night - 127)
                : 100
            }
            controls={controls}
            type="fan_min_max"
          />
        )}
        {[-1, 0, 1].indexOf(controller.settings.system.fan_type_select) > -1 && (
          <ShowSettingMinMax
            id="intake_fan_ec_max_night"
            show="intake_fan_ec_min_max_night"
            
            cur_value_max={values.intake_fan_ec_max_night}
            controller_value_max={controller.settings.ventilation.intake_fan_ec_max_night}
            cur_value_min={values.intake_fan_ec_min_night}
            controller_value_min={controller.settings.ventilation.intake_fan_ec_min_night}
            min={9}
            max={100}
            controls={controls}
            type="fan_min_max"
          />
        )}
        {[-1, 0, 2].indexOf(controller.settings.system.fan_type_select) > -1 && (
          <ShowSettingMinMax
            id="exhaust_fan_ac_max_night"
            show="exhaust_fan_ac_min_max_night"
            
            cur_value_max={values.exhaust_fan_ac_max_night}
            controller_value_max={controller.settings.ventilation.exhaust_fan_ac_max_night}
            cur_value_min={values.exhaust_fan_ac_min_night}
            controller_value_min={controller.settings.ventilation.exhaust_fan_ac_min_night}
            min={controller.settings.advanced.fan_ac_exhaust_turnoff - 1}
            max={
              values.pressure_percent_night - 127 < 0
                ? 100 + (values.pressure_percent_night - 127)
                : 100
            }
            off_at={controller.settings.advanced.fan_ac_exhaust_turnoff - 1}
            controls={controls}
            type="fan_min_max"
          />
        )}
        {[-1, 0, 2].indexOf(controller.settings.system.fan_type_select) > -1 && (
          <ShowSettingMinMax
            id="intake_fan_ac_max_night"
            show="intake_fan_ac_min_max_night"
            
            cur_value_max={values.intake_fan_ac_max_night}
            controller_value_max={controller.settings.ventilation.intake_fan_ac_max_night}
            cur_value_min={values.intake_fan_ac_min_night}
            controller_value_min={controller.settings.ventilation.intake_fan_ac_min_night}
            min={controller.settings.advanced.fan_ac_intake_turnoff - 1}
            max={100}
            off_at={controller.settings.advanced.fan_ac_intake_turnoff - 1}
            controls={controls}
            type="fan_min_max"
          />
        )}
        <ShowSetting
          id="pressure_percent_night"
          cur_value={values.pressure_percent_night}
          controller_value={controller.settings.ventilation.pressure_percent_night}
          controls={controls}
          
          
          type="negative"
        />
      </SettingGroupGrid>

      <SettingGroupGrid label={t('deviation_rpm_reduction')}>
        <ShowSetting
          id="deviation_rpm_reduction"
          cur_value={values.deviation_rpm_reduction}
          controller_value={controller.settings.ventilation.deviation_rpm_reduction}
          controls={controls}
          
          
          type="float"
        />
      </SettingGroupGrid>

      <SettingGroupGrid label={t('neg_pressure_header')}>
        <ShowSetting
          id="pressure_pa"
          cur_value={values.pressure_pa}
          controller_value={controller.settings.ventilation.pressure_pa}
          controls={controls}
          
          
        />
      </SettingGroupGrid>

      <SettingActions
        onReset={controls.resetSettings}
        onSave={controls.saveSettings}
      />
    </React.Fragment>
  );
}
