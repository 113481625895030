import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';

import {
  EnableDisableDaytimeSelect,
  AbsoluteRelativeSelect,
  DehumidificationModeSelect,
  EnableDisableSelect
} from '../FormWidgets/Select.js';

import { ShowSetting } from '../FormWidgets/ShowSliderSetting';
import Desc from '../FormWidgets/Description';
import { SettingGroupBlock, SettingGroupGrid, ValueBox } from '../FormWidgets/SettingGroup';
import { LoadSettingsDialog } from '../Utils/load_settings';
import { dump } from '../Utils/utils';

import {
  settingControls,
  useSavedSettings
} from './settingFunctions';

import { SettingActions } from '../components';

import useStateManager from '../StateManager.js';

export default function ClimateSettings({
  contType,
  ...other
}) {
  let { t } = useTranslation();
  let { id, tab_id } = useParams();
  
 const stateManager = useStateManager();
 const controller = stateManager.getControllerById (id);
 const [values, setValues] = useState (controller.settings[tab_id]);
 const controls = settingControls (controller, tab_id, stateManager.saveSettings, values, setValues);
 const { openLoadSettingsDialog, closeLoadSettingsDialog } = useSavedSettings (controller, controls);

  return (
    <React.Fragment>
    
      <LoadSettingsDialog
        open={openLoadSettingsDialog}
        handleClose={closeLoadSettingsDialog}
        controls={controls}
      />

      <SettingActions
        onSave={controls.saveSettings}
        onReset={controls.resetSettings}
      />

      <SettingGroupGrid label={t('humidity_sp')}>
        <ShowSetting
          id="humidity_sp_day"
          cur_value={values.humidity_sp_day}
          controller_value={controller.settings.climate.humidity_sp_day}
		  controls={controls}
          disabled={
            values.vpd_calc_enable === 2 ||
            values.vpd_calc_enable === 1 ||
            values.vpd_calc_enable === -1
          }
        />
        <ShowSetting
          id="humidity_sp_night"
          cur_value={values.humidity_sp_night}
          controller_value={controller.settings.climate.humidity_sp_night}
          
			controls={controls}
          
          disabled={
            values.vpd_calc_enable === 1 ||
            values.vpd_calc_enable === -1
          }
        />
      </SettingGroupGrid>

      <SettingGroupGrid label={t('temp_sp')}>
        <ShowSetting
          id="temp_sp_day"
          cur_value={values.temp_sp_day}
          controller_value={controller.settings.climate.temp_sp_day}
                    controller={controller}
          cat={tab_id}
          
			controls={controls}
          
          
          type="float"
        />
        <ShowSetting
          id="temp_sp_night"
          cur_value={values.temp_sp_night}
          controller_value={controller.settings.climate.temp_sp_night}
          
			controls={controls}
          
          
          type="float"
        />
      </SettingGroupGrid>

      {contType === 5055 && (
        <SettingGroupGrid label={t('vapour_pressure_deficit_vpd')}>
          <ShowSetting
            id="vpd_sp"
            cur_value={values.vpd_sp}
            controller_value={controller.settings.climate.vpd_sp}
            
			controls={controls}
            
            
            type="float"
          />
          <ShowSetting
            id="vpd_sp_night"
            cur_value={values.vpd_sp_night}
            controller_value={controller.settings.climate.vpd_sp_night}
            
			controls={controls}
            
            
            type="float"
          />
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="vpd_calc_enable" name="VPD Calculation Enable" />
            </Typography>
            <EnableDisableDaytimeSelect
              value={values.vpd_calc_enable}
              name="vpd_calc_enable"
              
              onChange={(event, value) =>
                controls.setValue ('vpd_calc_enable', event.target.value)
              }
            />
          </ValueBox>
        </SettingGroupGrid>
      )}

      {contType === 5055 && (
        <SettingGroupGrid label={t('preheat_night_day')}>
          <ShowSetting
            id="preheat_night_day"
            cur_value={values.preheat_night_day}
            controller_value={controller.settings.climate.preheat_night_day}
            
			controls={controls}
            
            
          />
        </SettingGroupGrid>
      )}

      {contType === 5055 && (
        <SettingGroupGrid label={t('temp_sp_heater')}>
          <ShowSetting
            id="temp_sp_heater_day"
            cur_value={values.temp_sp_heater_day}
            controller_value={controller.settings.climate.temp_sp_heater_day}
            max={values.temp_sp_day / 10 - 1}
            
			controls={controls}
            
            
            type="float"
            off_at={49}
          />
          <ShowSetting
            id="temp_sp_heater_night"
            cur_value={values.temp_sp_heater_night}
            controller_value={controller.settings.climate.temp_sp_heater_night}
            max={values.temp_sp_night / 10 - 1}
            
			controls={controls}
            
            
            type="float"
          />
        </SettingGroupGrid>
      )}

      <SettingGroupBlock label={t('temperature_setpoint_heating_mat')}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="toggle_temp_heating_mat_day" />
            </Typography>
            <AbsoluteRelativeSelect
              value={values.toggle_temp_heating_mat_day}
              name="toggle_temp_heating_mat_day"
              
              onChange={(event, value) =>
                 controls.setValue ('toggle_temp_heating_mat_day', event.target.value)
              }
            />
          </ValueBox>
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="toggle_temp_heating_mat_night" />
            </Typography>
            <AbsoluteRelativeSelect
              value={values.toggle_temp_heating_mat_night}
              name="toggle_temp_heating_mat_night"
              
              onChange={(event, value) =>
                controls.setValue ('toggle_temp_heating_mat_night', event.target.value)
              }
            />
          </ValueBox>
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'stretch' }}>
          {values.toggle_temp_heating_mat_day === 1 ? (
            <ShowSetting
              id="temp_sp_heating_mat_day_abs"
              cur_value={values.temp_sp_heating_mat_day_abs}
              controller_value={controller.settings.climate.temp_sp_heating_mat_day_abs}
              
			controls={controls}
              
              
              type="float"
            />
          ) : (
            <ShowSetting
              id="temp_sp_heating_mat_day_rel"
              cur_value={values.temp_sp_heating_mat_day_rel}
              controller_value={controller.settings.climate.temp_sp_heating_mat_day_rel}
			controls={controls}
              
              
              type="float"
            />
          )}
          {values.toggle_temp_heating_mat_night === 1 ? (
            <ShowSetting
              id="temp_sp_heating_mat_night_abs"
              cur_value={values.temp_sp_heating_mat_night_abs}
              controller_value={controller.settings.climate.temp_sp_heating_mat_night_abs}
              
			controls={controls}
              
              
              type="float"
            />
          ) : (
            <ShowSetting
              id="temp_sp_heating_mat_night_rel"
              cur_value={values.temp_sp_heating_mat_night_rel}
              controller_value={controller.settings.climate.temp_sp_heating_mat_night_rel}
			controls={controls}
              
              
              type="float"
            />
          )}
        </Box>
      </SettingGroupBlock>

      <SettingGroupGrid label={t('dehumidification_hl')}>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="dehumidification_mode" name="Select Dehumidification Mode" />
          </Typography>
          <DehumidificationModeSelect
            value={values.dehumidification_mode}
            name="dehumidification_mode"
            
            onChange={(event, value) =>
               controls.setValue ('dehumidification_mode', event.target.value)
            }
          />
        </ValueBox>
        {contType === 5055 && (
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="heating_to_dehumidify_en" />
            </Typography>
            <EnableDisableSelect
              value={values.heating_to_dehumidify_en}
              name="heating_to_dehumidify_en"
              
              onChange={(event, value) =>
                 controls.setValue ('heating_to_dehumidify_en', event.target.value)
              }
            />
          </ValueBox>
        )}
      </SettingGroupGrid>

      <SettingActions
        onSave={controls.saveSettings}
        onReset={controls.resetSettings}
      />
    </React.Fragment>
  );
}
