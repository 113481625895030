import React from 'react';
import { AppBar, makeStyles, Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { tabs } from './tabsConfig.js';

export function ControllerTabs(props) {
  let { tab_id } = useParams();
  let { t } = useTranslation();

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      backgroundColor: theme.palette.background.paper
    }
  }));
  const classes = useStyles();

  const tab_index = tabs.findIndex((x) => x.id === tab_id) + 1;

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={tab_index}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="controller settings tabs"
        >
          <Tab
            label="Info"
            component={Link}
            to={props.url}
            key="controller-tab-0"
            aria-controls="controller-tabpanel-0"
          />
          {tabs.map((tab, index) => (
            <Tab
              label={t(tab.id)}
              component={Link}
              to={`${props.url}/${tab.id}`}
              key={`controller-tab-${index + 1}`}
              id={`controller-tab-${index + 1}`}
              aria-controls={`controller-tabpanel-${index}`}
            />
          ))}
        </Tabs>
      </AppBar>
    </div>
  );
}
