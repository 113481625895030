export function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

export function getScrollContent() {
  return {
    maxHeight: 300,
    position: 'absolute',
    overflow: 'auto',
    lineBreak: 'strict',
    wordBreak: 'break-all'
  };
}

export function getCloseStyle() {
  const top = 0;
  return {
    cursor: 'pointer',
    position: 'relative',
    float: 'right',
    top: `${top}px`
  };
}

export function GetAlarmLogButtonStyle() {
  const right = 45;
  const bottom = 3;
  return {
    position: 'absolute',
    right: `${right}%`,
    bottom: `${bottom}em`
  };
}

export function getAlertStyle() {
  const magrin_bottom = 1;
  return {
    marginBottom: `${magrin_bottom}em`
  };
}

export function getMessageButtonStyle(active, blinking, use_color = 'red') {
  const margin_right = 1;
  const animation = blinking ? 'blinker 2s linear infinite' : '';
  const color = active || blinking ? use_color : 'white';
  return {
    color: `${color}`,
    cursor: 'pointer',
    marginRight: `${margin_right}em`,
    animation: `${animation}`
  };
}

export function getSimulationButtonStyle(active) {
  const top = 10;
  const margin_left = 0.5;
  const color = active ? 'orange' : 'white';
  return {
    color: `${color}`,
    cursor: 'pointer',
    top: `${top}px`,
    marginLeft: `${margin_left}em`
  };
}

export function GetHeadingStyle() {
  const margin_top = 1;
  return {
    marginTop: `${margin_top}em`
  };
}

export function GetSaveButtonStyle() {
  const margin_top = 1;
  return {
    marginTop: `${margin_top}em`,
    textAlign: 'center'
  };
}

export const FabStyle = {
  margin: 0,
  right: 20,
  top: 'auto',
  left: 'auto',
  position: 'absolute'
};

export const FabStyleBottom = {
  margin: 0,
  right: 20,
  bottom: 10,
  top: 'auto',
  left: 'auto',
  position: 'fixed'
};
