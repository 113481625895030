import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import { ShowSetting, ShowSettingMinMax } from '../FormWidgets/ShowSliderSetting';
import { EnableDayTimeDisableSelect, RoomScalseSelect } from '../FormWidgets/Select.js';
import Desc from '../FormWidgets/Description';
import { SettingGroupGrid, ValueBox } from '../FormWidgets/SettingGroup';
import { room_scale_mapping } from '../Constants/constants';
import { LoadSettingsDialog } from '../Utils/load_settings';



import {
  settingControls,
  useSavedSettings,
} from './settingFunctions';

import { SettingActions } from '../components';

import useStateManager from '../StateManager.js';

export default function Co2Settings({ contType, ...other }) {
  let { t } = useTranslation();
  let { id, tab_id } = useParams();
  
  const stateManager = useStateManager();
  const controller = stateManager.getControllerById (id);
  const [values, setValues] = useState (controller.settings[tab_id]);
  const controls = settingControls (controller, tab_id, stateManager.saveSettings, values, setValues);
  const { openLoadSettingsDialog, closeLoadSettingsDialog } = useSavedSettings (controller, controls);

  return (
    <React.Fragment>
      <LoadSettingsDialog
        open={openLoadSettingsDialog}
        handleClose={closeLoadSettingsDialog}
        resetSettings={controls.resetTempSettings}
      />

      <SettingActions
        onSave={controls.saveSettings}
        onReset={controls.resetSettings}
      />

      <SettingGroupGrid label={t('co2_settings')}>
        <ShowSetting
          id="co2_sp"
          cur_value={values.co2_sp}
          controller_value={controller.settings.co2.co2_sp}
          controls={controls}
          
          
        />
        <ShowSetting
          id="room_vol_sp"
          cur_value={values.room_vol_sp}
          controller_value={controller.settings.co2.room_vol_sp}
          controls={controls}
          
          max={room_scale_mapping['room_vol_sp'][values.room_scale]}
          
          type="float"
        />
        <ShowSetting
          id="co2_flow"
          cur_value={values.co2_flow}
          controller_value={controller.settings.co2.co2_flow}
          controls={controls}
          
          max={room_scale_mapping['co2_flow'][values.room_scale]}
          
          type="float"
        />
        <ShowSetting
          id="exhaust_fans_flow"
          cur_value={values.exhaust_fans_flow}
          controller_value={controller.settings.co2.exhaust_fans_flow}
          max={room_scale_mapping['exhaust_fans_flow'][values.room_scale]}
          controls={controls}
          
          
        />
        <ValueBox>
          <Typography>
            <Desc id="co2_control" />
          </Typography>
          <EnableDayTimeDisableSelect
            value={values.co2_control}
            name="co2_control"
            
            onChange={(event, value) =>
              controls.setValue ('co2_control', event.target.value)
            }
          />
        </ValueBox>
        <ValueBox>
          <Typography>
            <Desc id="room_scale" />
          </Typography>
          <RoomScalseSelect
            value={values.room_scale}
            name="room_scale"
            
            onChange={(event, value) =>
              controls.setValue ('room_scale', event.target.value)
            }
          />
        </ValueBox>
      </SettingGroupGrid>

      <SettingGroupGrid label={t('temperature_co2')}>
        <ShowSetting
          id="temp_sp_co2"
          cur_value={values.temp_sp_co2}
          controller_value={controller.settings.co2.temp_sp_co2}
          controls={controls}
          
          
          type="float"
        />

        {contType === 5055 && (
          <ShowSetting
            id="temp_heater_co2"
            cur_value={values.temp_heater_co2}
            controller_value={controller.settings.co2.temp_heater_co2}
            controls={controls}
            
            max={Number.parseFloat((values.temp_sp_co2 - 10) / 10)}
            
            type="float"
            off_at={49}
          />
        )}
      </SettingGroupGrid>

      <SettingGroupGrid label={t('ventilation_co2')}>
        {[-1, 0, 1].indexOf(controller.settings.system.fan_type_select) > -1 && (
          <ShowSettingMinMax
            id="exhaust_fan_ec_max_co2"
            show="exhaust_fans_ec_min_max_co2"
            cur_value_max={values.exhaust_fan_ec_max_co2}
            controller_value_max={controller.settings.co2.exhaust_fan_ec_max_co2}
            cur_value_min={values.exhaust_fan_ec_min_co2}
            controller_value_min={controller.settings.co2.exhaust_fan_ec_min_co2}
            min={9}
            max={
              controller.settings.ventilation.pressure_percent_day - 127 < 0
                ? 100 + (controller.settings.ventilation.pressure_percent_day - 127)
                : 100
            }
            controls={controls}
            
            
            type="fan_min_max"
          />
        )}
        {[-1, 0, 1].indexOf(controller.settings.system.fan_type_select) > -1 && (
          <ShowSettingMinMax
            id="intake_fan_ec_max_co2"
            show="intake_fan_ec_min_max_co2"
            cur_value_max={values.intake_fan_ec_max_co2}
            controller_value_max={controller.settings.co2.intake_fan_ec_max_co2}
            cur_value_min={values.intake_fan_ec_min_co2}
            controller_value_min={controller.settings.co2.intake_fan_ec_min_co2}
            min={9}
            max={100}
            controls={controls}
            
            
            type="fan_min_max"
          />
        )}
        {[-1, 0, 2].indexOf(controller.settings.system.fan_type_select) > -1 && (
          <ShowSettingMinMax
            id="exhaust_fan_ac_max_co2"
            show="exhaust_fans_ac_min_max_co2"
            cur_value_max={values.exhaust_fan_ac_max_co2}
            controller_value_max={controller.settings.co2.exhaust_fan_ac_max_co2}
            cur_value_min={values.exhaust_fan_ac_min_co2}
            controller_value_min={controller.settings.co2.exhaust_fan_ac_min_co2}
            min={controller.settings.advanced.fan_ac_exhaust_turnoff - 1}
            max={
              controller.settings.ventilation.pressure_percent_day - 127 < 0
                ? 100 + (controller.settings.ventilation.pressure_percent_day - 127)
                : 100
            }
            off_at={controller.settings.advanced.fan_ac_exhaust_turnoff - 1}
            controls={controls}
            
            
            type="fan_min_max"
          />
        )}
        {[-1, 0, 2].indexOf(controller.settings.system.fan_type_select) > -1 && (
          <ShowSettingMinMax
            id="intake_fan_ac_max_co2"
            show="intake_fan_ac_min_max_co2"
            cur_value_max={values.intake_fan_ac_max_co2}
            controller_value_max={controller.settings.co2.intake_fan_ac_max_co2}
            cur_value_min={values.intake_fan_ac_min_co2}
            controller_value_min={controller.settings.co2.intake_fan_ac_min_co2}
            min={controller.settings.advanced.fan_ac_intake_turnoff - 1}
            max={100}
            off_at={controller.settings.advanced.fan_ac_intake_turnoff - 1}
            controls={controls}
            
            
            type="fan_min_max"
          />
        )}
      </SettingGroupGrid>

      <SettingActions
        
        onSave={controls.saveSettings}
        onReset={controls.resetSetting}
      />
    </React.Fragment>
  );
}
