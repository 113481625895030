import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useLayoutEffect, useState } from 'react';

/**
 *
 * @returns True, if the device is phone else false
 */
export const useIsMobile = () => {
  const matches = useMediaQuery('(max-width:600px)');
  const [isMobile, setIsMobile] = useState(matches);

  useLayoutEffect(() => {
    setIsMobile(matches);
  }, [matches]);

  return isMobile;
};
