import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './assets/locales/en/translation.json';
import translationDE from './assets/locales/de/translation.json';
import translationFR from './assets/locales/fr/translation.json';
import translationES from './assets/locales/es/translation.json';
import translationIT from './assets/locales/it/translation.json';
import translationKEYS from './assets/locales/keys/translation.json';

const fallbackLng = ['de'];
const availableLanguages = ['en', 'de', 'fr', 'es', 'it', 'keys'];

const resources = {
  en: {
    translation: translationEN
  },
  de: {
    translation: translationDE
  },
  fr: {
    translation: translationFR
  },
  es: {
    translation: translationES
  },
  it: {
    translation: translationIT
  },
  keys: {
    translation: translationKEYS
  }
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng,

  detection: {
    checkWhitelist: true
  },

  debug: false,

  whitelist: availableLanguages,

  interpolation: {
    escapeValue: false
  }
});

export default i18n;
