import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { alarmMapping, resolutions } from '../Constants/constants';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    minWidth: 120,
    color: theme.palette.fontcolor
  }
}));

export function EnableDisableSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('disable')}</MenuItem>
      <MenuItem value={1}>{t('enable')}</MenuItem>
    </Select>
  );
}
export function OffOnSettings({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('off')}</MenuItem>
      <MenuItem value={1}>{t('on')}</MenuItem>
    </Select>
  );
}

export function SwitchSettings({ value, onChange, ...other }) {
  let { t } = useTranslation();
  return (
    <FormControlLabel control={<Switch onChange={onChange} {...other} />} label={t('on_off')} />
  );
}

export function ApplyDeleteSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('auto_save_delete')}</MenuItem>
      <MenuItem value={1}>{t('auto_save_paste')}</MenuItem>
    </Select>
  );
}

export function DisplayTimeoutSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('displ_timeout_60')}</MenuItem>
      <MenuItem value={1}>{t('displ_timeout_100')}</MenuItem>
      <MenuItem value={2}>{t('displ_timeout_160')}</MenuItem>
      <MenuItem value={3}>{t('displ_timeout_on')}</MenuItem>
    </Select>
  );
}

export function EnableDayTimeDisableSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('disable')}</MenuItem>
      <MenuItem value={1}>{t('enable_at_daytime')}</MenuItem>
    </Select>
  );
}

export function EnableDisableDaytimeSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('disable')}</MenuItem>
      <MenuItem value={1}>{t('enable_always')}</MenuItem>
      <MenuItem value={2}>{t('enable_at_daytime')}</MenuItem>
    </Select>
  );
}
export function AbsoluteRelativeSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('relative')}</MenuItem>
      <MenuItem value={1}>{t('absolute')}</MenuItem>
    </Select>
  );
}

export function RoomScalseSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('small')}</MenuItem>
      <MenuItem value={1}>{t('medium')}</MenuItem>
      <MenuItem value={2}>{t('large')}</MenuItem>
    </Select>
  );
}

export function EcAcSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('show_ac_ec')}</MenuItem>
      <MenuItem value={1}>{t('show_ec')}</MenuItem>
      <MenuItem value={2}>{t('show_ac')}</MenuItem>
    </Select>
  );
}

export function DehumidificationModeSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('off')}</MenuItem>
      <MenuItem value={1}>{t('low')}</MenuItem>
      <MenuItem value={2}>{t('medium')}</MenuItem>
      <MenuItem value={3}>{t('high')}</MenuItem>
    </Select>
  );
}

export function RegTypeCo2Select({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('fuzzy')}</MenuItem>
      <MenuItem value={1}>{t('P')}</MenuItem>
      <MenuItem value={2}>{t('PD')}</MenuItem>
    </Select>
  );
}

export function RegTypeSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('PD')}</MenuItem>
      <MenuItem value={1}>{t('P')}</MenuItem>
    </Select>
  );
}

export function RegTypeHumidifierSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('PD')}</MenuItem>
      <MenuItem value={1}>{t('P')}</MenuItem>
      <MenuItem value={2}>{t('PID')}</MenuItem>
    </Select>
  );
}

export function TempFormatSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('celsius')}</MenuItem>
      <MenuItem value={1}>{t('fahrenheit')}</MenuItem>
    </Select>
  );
}

export function DateFormatSelect({ ...other }) {
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>YYYY-MM-DD</MenuItem>
      <MenuItem value={1}>DD.MM.YYYY</MenuItem>
    </Select>
  );
}

export function LanguageSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('english')}</MenuItem>
      <MenuItem value={1}>{t('german')}</MenuItem>
    </Select>
  );
}

export function AverageMinMaxSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('average')}</MenuItem>
      <MenuItem value={1}>{t('min')}</MenuItem>
      <MenuItem value={2}>{t('max')}</MenuItem>
    </Select>
  );
}

export function MultiOutSelect({ not_pro, type, ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem disabled={type === 1 || type === 2} value={1}>
        {t('intake_fans_ac')}
      </MenuItem>
      <MenuItem disabled={type === 1 || type === 2} value={0}>
        {t('exhaust_fans_ac')}
      </MenuItem>
      <MenuItem value={2}>{t('humidifier')}</MenuItem>
      <MenuItem value={3}>{t('dehumidifier')}</MenuItem>
      <MenuItem value={4}>{t('lights')}</MenuItem>
      <MenuItem value={8}>{t('co2_valve')}</MenuItem>
      <MenuItem value={9}>{t('co2_generator')}</MenuItem>
      <MenuItem value={6}>{t('heating_mat')}</MenuItem>
      {!not_pro && (
        <MenuItem disabled={type === 0 || type === 2} value={10}>
          {t('heater')}
        </MenuItem>
      )}
      <MenuItem value={7}>{t('chiller')}</MenuItem>
      <MenuItem value={5}>{t('interval')}</MenuItem>
      {!not_pro && <MenuItem value={21}>{t('interv_1_inv')}</MenuItem>}
      {!not_pro && <MenuItem value={22}>{t('interval_2')}</MenuItem>}
      {!not_pro && <MenuItem value={23}>{t('interv_2_inv')}</MenuItem>}
      <MenuItem value={11}>{t('event1')}</MenuItem>
      <MenuItem value={12}>{t('event2')}</MenuItem>
      <MenuItem value={13}>{t('event3')}</MenuItem>
      <MenuItem value={14}>{t('event4')}</MenuItem>
      {!not_pro && <MenuItem value={24}>{t('event5')}</MenuItem>}
      {!not_pro && <MenuItem value={25}>{t('event6')}</MenuItem>}
      {!not_pro && <MenuItem value={26}>{t('event7')}</MenuItem>}
      {!not_pro && <MenuItem value={27}>{t('event8')}</MenuItem>}
      <MenuItem value={15}>{t('event1+2')}</MenuItem>
      <MenuItem value={16}>{t('event3+4')}</MenuItem>
      {!not_pro && <MenuItem value={28}>{t('event5+6')}</MenuItem>}
      {!not_pro && <MenuItem value={29}>{t('event5-8')}</MenuItem>}
      {!not_pro && <MenuItem value={30}>{t('event1-8')}</MenuItem>}
      <MenuItem value={17}>{t('event1+2+3')}</MenuItem>
      <MenuItem value={18}>{t('event1+2+3+4')}</MenuItem>
      <MenuItem value={19}>{t('disabled')}</MenuItem>
      <MenuItem value={20}>{t('out_en_always')}</MenuItem>
    </Select>
  );
}

export function EventGapSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={0}>{t('every_day')}</MenuItem>
      <MenuItem value={1}>{t('every_2_day')}</MenuItem>
      <MenuItem value={2}>{t('every_3_day')}</MenuItem>
      <MenuItem value={3}>{t('every_4_day')}</MenuItem>
      <MenuItem value={4}>{t('every_5_day')}</MenuItem>
      <MenuItem value={5}>{t('every_6_day')}</MenuItem>
      <MenuItem value={6}>{t('every_7_day')}</MenuItem>
      <MenuItem value={7}>{t('every_8_day')}</MenuItem>
      <MenuItem value={8}>{t('every_9_day')}</MenuItem>
      <MenuItem value={9}>{t('every_10_day')}</MenuItem>
      <MenuItem value={10}>{t('every_11_day')}</MenuItem>
      <MenuItem value={11}>{t('every_12_day')}</MenuItem>
      <MenuItem value={12}>{t('every_13_day')}</MenuItem>
      <MenuItem value={13}>{t('every_14_day')}</MenuItem>
      <MenuItem value={14}>{t('every_15_day')}</MenuItem>
      <MenuItem value={15}>{t('every_16_day')}</MenuItem>
      <MenuItem value={16}>{t('every_17_day')}</MenuItem>
      <MenuItem value={17}>{t('every_18_day')}</MenuItem>
      <MenuItem value={18}>{t('every_19_day')}</MenuItem>
      <MenuItem value={19}>{t('every_20_day')}</MenuItem>
      <MenuItem value={20}>{t('every_21_day')}</MenuItem>
      <MenuItem value={21}>{t('every_22_day')}</MenuItem>
      <MenuItem value={22}>{t('every_23_day')}</MenuItem>
      <MenuItem value={23}>{t('every_24_day')}</MenuItem>
      <MenuItem value={24}>{t('every_25_day')}</MenuItem>
      <MenuItem value={25}>{t('every_26_day')}</MenuItem>
      <MenuItem value={26}>{t('every_27_day')}</MenuItem>
      <MenuItem value={27}>{t('every_28_day')}</MenuItem>
    </Select>
  );
}

export function FirmwareSelect({ versions, contApiVersion, contFWVersion, ...other }) {
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={-1}>---</MenuItem>
      {versions.map((version, i) => (
        <MenuItem
          value={i}
          disabled={
            contApiVersion < 'AB' &&
            contApiVersion != '#latest' &&
            contFWVersion.indexOf('0.00.18') != 5
          }
        >
          {version.name}
        </MenuItem>
      ))}
    </Select>
  );
}

export function AlarmSelect({ ...other }) {
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value="all">All</MenuItem>
      {Object.entries(alarmMapping).map(([alarm_id, alarm]) => (
        <MenuItem value={alarm_id}>{alarm.name}</MenuItem>
      ))}
    </Select>
  );
}

export function HistoryResSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={resolutions.recent}>{t('history_type_recent')}</MenuItem>
      <MenuItem value={resolutions.daily}>{t('history_type_daily')}</MenuItem>
      <MenuItem value={resolutions.full}>{t('history_type_full')}</MenuItem>
    </Select>
  );
}

export function TimeFormatSelect({ ...other }) {
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={'DD.MM.YYYY HH:mm:ss'}>17.01.2022 16:17:33</MenuItem>
      <MenuItem value={'YYYY-MM-DD HH:mm:ss'}>2022-01-17 16:17:33</MenuItem>
      <MenuItem value={'YYYY-MM-DD hh:mm:ss a'}>2022-01-17 04:17:33 PM</MenuItem>
    </Select>
  );
}

export function DelimiterSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={';'}>{t('semicolon')}</MenuItem>
      <MenuItem value={','}>{t('comma')}</MenuItem>
    </Select>
  );
}

export function DecimalSelect({ delimiter, ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={','} disabled={delimiter === ','}>
        {t('comma')}
      </MenuItem>
      <MenuItem value={'.'}>{t('dot')}</MenuItem>
    </Select>
  );
}

export function TokenTypeSelect({ ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();
  return (
    <Select className={classes.selectEmpty} {...other}>
      <MenuItem value={'read'}>{t('Read')}</MenuItem>
    </Select>
  );
}
