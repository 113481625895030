import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CheckIcon from '@material-ui/icons/Check';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { getMessageButtonStyle } from '../Styles/styles.js';
import { PrintDate } from '../Utils/utils.js';
import { alarmMapping } from '../Constants/constants';

import useStateManager from '../StateManager.js';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxHeight: '40vh'
  }
}));

function ConvertValue(id, value) {
  const is_float = id in alarmMapping && alarmMapping[id]['type'] === 'float';
  const unit = id in alarmMapping ? alarmMapping[id]['unit'] : '';
  const converted_value = is_float ? Number.parseFloat(value / 10).toFixed(1) : value;
  return converted_value + ' ' + unit;
}

function GetAlarmIcon({ was_triggered }) {
  if (was_triggered) return <ReportProblemIcon style={{ color: 'red', marginRight: '0.5em' }} />;
  else return <CheckIcon style={{ color: 'green', marginRight: '0.5em' }} />;
}

function AlarmInfo({ id, name, was_triggered, controller, value }) {
  return (
    <>
      <b>{controller.name}</b> - <i>{name}</i>
    </>
  );
}

function AlarmValue({ id, name, was_triggered, value }) {
  if (was_triggered && !(id in alarmMapping && alarmMapping[id].type === 'bool')) {
    const value_with_unit = ConvertValue(id, value);
    return value_with_unit;
  }
  return <></>;
}

function AlarmStatus({ state, timestamp }) {
  return (
    <>
      {state}: <small>({PrintDate(timestamp, 'l LTS')})</small>
    </>
  );
}

function AlarmEntry({ alarm, index, resolveAlarm }) {
  let { t } = useTranslation();
  return (
    <ListItem key={'alarm_entry_' + index}>
      <ListItemIcon
        onClick={resolveAlarm}
        style={{ cursor: 'pointer' }}
        title={t('resolve_alarm_info')}
      >
        <GetAlarmIcon was_triggered={true} />
      </ListItemIcon>
      <ListItemText
        primary={
          <AlarmInfo
            id={alarm.name}
            name={t(alarm.name)}
            was_triggered={true}
            controller={alarm.controller}
          />
        }
        secondary={
          <AlarmValue
            id={alarm.name}
            name={t(alarm.name)}
            was_triggered={true}
            value={alarm.value}
          />
        }
      />
    </ListItem>
  );
}

function AlarmLogEntry({ logEntry, index }) {
  let { t } = useTranslation();
  return (
    <ListItem key={'log_entry_' + index}>
      <ListItemIcon>
        <GetAlarmIcon was_triggered={logEntry.triggered} />
      </ListItemIcon>
      <ListItemText
        secondary={
          <AlarmStatus
            state={logEntry.triggered ? t('triggered') : t('resolved')}
            timestamp={logEntry.ts}
          />
        }
        primary={
          <AlarmInfo
            id={logEntry.id}
            name={t(logEntry.id)}
            was_triggered={logEntry.triggered}
            controller={logEntry.controller}
            value={logEntry.value}
          />
        }
      />
    </ListItem>
  );
}

export default function AlarmModal({ logged_in, ...other }) {
  let { t } = useTranslation();
  let { enqueueSnackbar } = useSnackbar();
  
  const stateManager = useStateManager(); // display values
  const store = stateManager.store(); // change values

  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [open_alarms, setOpenAlarms] = React.useState(false);

  const ResolveAlarm = (controller, id) => {
    enqueueSnackbar(t('resolved_alarm_manually') + ' ' + controller.id + ': ' + t(id) + '.', {
      variant: 'info'
    });
    
    stateManager.wsManager.sendWSMsg ( controller, 'resolve_alarm', { alarm_id: id } )
  };

  // Init alarms
  const handleOpenAlarms = () => {
    store.alarms.unreadAlarms = false;
    setOpenAlarms(true);
  };
  const handleCloseAlarms = () => {
    setOpenAlarms(false);
  };
  const active = stateManager.alarms.activeAlarms.length > 0;
  const messageButton = getMessageButtonStyle (active, stateManager.alarms.unreadAlarms);

  const [open_alarm_log, setOpenAlarmLog] = React.useState(false);
  const handleOpenAlarmLog = () => {
    setOpenAlarmLog(true);
  };
  const handleCloseAlarmLog = () => {
    setOpenAlarmLog(false);
  };

  if (logged_in) {
    return (
      <div style={{ zIndex: 2 }}>
        <AddAlertIcon style={messageButton} onClick={handleOpenAlarms}></AddAlertIcon>
        <Dialog
          open={open_alarms}
          onClose={handleCloseAlarms}
          classes={{ paper: classes.dialogPaper }}
          fullWidth={true}
          maxWidth="sm"
          aria-labelledby="simple-modal-title"
        >
          <DialogTitle id="form-dialog-message-title">Alarms</DialogTitle>
          <DialogContent>
            <List>
              { stateManager.alarms.activeAlarms.map((alarm, i) => (
                <AlarmEntry
                  alarm={alarm}
                  index={i}
                  resolveAlarm={() => ResolveAlarm(alarm.controller, alarm['name'])}
                />
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleOpenAlarmLog} color="primary">
              {t('alarm_log')}
            </Button>
            <Button onClick={handleCloseAlarms} color="primary">
              {t('close')}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={open_alarm_log}
          onClose={handleCloseAlarms}
          classes={{ paper: classes.dialogPaper }}
          fullWidth={true}
          maxWidth="sm"
          aria-labelledby="simple-modal-title"
        >
          <DialogTitle id="form-dialog-message-title">Alarm Log</DialogTitle>
          <DialogContent>
            <List>
              {  [...stateManager.alarms.alarmsLog].reverse().map((logEntry, i) => (
                <AlarmLogEntry logEntry={logEntry} index={i} />
              )) }
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAlarmLog} color="primary">
              {t('close')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return <></>;
  }
}
