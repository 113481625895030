import i18next from 'i18next';
import moment from 'moment-with-locales-es6';

const momentWithLocale = (...args) => {
  const language = i18next.language !== 'keys' ? i18next.language : 'de';
  moment.locale(language);
  return moment(...args);
};

export function diff(tp_a, tp_b) {
  return moment.duration(tp_b.diff(tp_a)).as('minutes');
}

export default momentWithLocale;
