import { initialRequests } from '../Constants/constants';
import {
  AlarmInList,
  EditAlarmLog,
} from '../Utils/utils.js';

import controllerOnMessage from './controllerOnMessageHook.js';

export default function globalOnMessage (wsManager, resp) {
	if (resp != null) {
		let command = '';
		try {
			command = resp['command'];
		if (command !== 'update_values') console.log('[global received] command: ', command);
		} catch (err) {
			alert('Wrong Json format! Please contact admins!');
			return;
		}

		const controller_id = resp.internal_id;
		const cur_controller = wsManager.store.getControllerById (controller_id);
		const controller_name = cur_controller !== undefined ? cur_controller.name : controller_id;
		
		const alarms = wsManager.store.alarms;
		const curActiveAlarms = alarms.activeAlarms;
		const timestamp = resp['data']?.['timestamp'];
		
		
		// cmd: resp_controllers_active:
		if (command === 'resp_controllers_active') {
			if ('data' in resp && resp['data'] != null && 'active_controllers' in resp['data']) {
				const active_controllers = resp['data']['active_controllers'];
				console.log('[global received] controllers_active: ', active_controllers);
				
				for (let key of Object.keys(active_controllers)) {
					const controller = wsManager.store.getControllerById (key);

					if (controller) {
						let new_status = active_controllers[key] === true ? 'issues' : 'bad';
						if (new_status !== 'bad') {
							const diff = Math.floor((Date.now() - controller.connection.last_received) / 1000);
							new_status = diff > 5 ? 'issues' : 'good';
						}
						
						// Only update if status acually changed changed
						if (controller.connection.status !== new_status) {
							controller.connection.status = new_status;
						}
						
						if (controller.connection.status !== 'bad' && !controller.connection.requested_all)
						{
							console.log ('[wsManager]');
							initialRequests?.forEach( (request) => {
								wsManager.sendWSMsg (controller, request);
							});
							
							controller.connection.requested_all = true;
						}
						
						
					}
				}
			}
		}
		// cmd: alarms_triggered:
		else if (command === 'alarms_triggered') {
			// unpack received data.
			const new_alarms = resp['data']['alarms'];

			// Iterate over all received alarms and add alarm to active alarms.
			for (const [alarm_id, value] of Object.entries(new_alarms)) {
				const alarm_joined_id = cur_controller.id + '_' + alarm_id;
				const cur_alarm = curActiveAlarms.find((x) => x.id === alarm_joined_id);
				
				if (cur_alarm === undefined || cur_alarm === null) {
					wsManager.store.alarms.activeAlarms.push ({
						controller: cur_controller,
						id: alarm_joined_id,
						name: alarm_id,
						value: value
					});
					
					wsManager.store.hooks.enqueueSnackbar(
						controller_name +
						' - Alarm "' +
						wsManager.store.hooks.t(alarm_id) +
						'" ' +
						wsManager.store.hooks.t('triggered') +
						' (' +
						value +
						')!',
						{ variant: 'error' }
					);
				} else {
					wsManager.store.hooks.enqueueSnackbar(
						controller_name +
						' - Alarm "' +
						wsManager.store.hooks.t(alarm_id) +
						'" ' +
						wsManager.store.hooks.t('value_updated') +
						': ' +
						value +
						'!',
						{ variant: 'error' }
					);
				}
				// Add to alarm-log.
				alarms.alarmsLog.push ({
					triggered: true,
					controller: cur_controller,
					id: alarm_id,
					ts: timestamp,
					value: value
				});
			}

			alarms.unreadAlarms = true;
		}
		
		// cmd: alarms_resolved:
		else if (command === 'alarms_resolved') {
			// unpack received data.
			const resolvedAlarms = resp['data']['alarm_keys'];

			// Iterate over all current alarms and add alarm to active alarms.
			alarms.activeAlarms = curActiveAlarms.filter ( (alarm) => {
				// Return whether alarm shall be removed.
				const alarm_in_list = AlarmInList(alarm['id'], resolvedAlarms, controller_id) === false;
				if (!alarm_in_list)
					wsManager.store.hooks.enqueueSnackbar(
						controller_name + ' - Alarm "' + wsManager.store.hooks.t(alarm['id']) + '" ' + wsManager.store.hooks.t('resolved') + '!',
						{ variant: 'success' }
					);
				return alarm_in_list;
			});

			// Add to alarm-log.
			for (let i = 0; i < resolvedAlarms.length; i++) {
				alarms.alarmsLog.push ({
					triggered: false,
					controller: cur_controller,
					id: resolvedAlarms[i],
					ts: timestamp
				});
			}
			
			alarms.unreadAlarms = alarms.activeAlarms.length > 0;
		}

		// cmd: send_full_alarm_log:
		else if (command === 'send_full_alarm_log') {
			const curAlarmLog = alarms.alarmsLog;
			const newAlarmLog = resp['data']['alarm_log'];
			for (var i = 0; i < newAlarmLog.length; i++) {
				const timestamp = newAlarmLog[i]['timestamp'];
				
				if ('triggered' in newAlarmLog[i]) {
					const triggeredAlarms = newAlarmLog[i]['triggered'];
					for (let key of Object.keys(triggeredAlarms)) {
						curAlarmLog.push ({
							triggered: true,
							controller: cur_controller,
							id: key,
							ts: timestamp,
							value: triggeredAlarms[key]
						});
					}
				} else if ('resolved' in newAlarmLog[i]) {
					const resolvedAlarms = newAlarmLog[i]['resolved'];
					for (let key of Object.keys(resolvedAlarms)) {
						curAlarmLog.push ({
							triggered: false,
							controller: cur_controller,
							id: resolvedAlarms[key],
							ts: timestamp
						});
					}
				} else {
					console.log('[global-ws] wrong category (resolved/ triggered)');
				}
			}
			
			alarms.alarmsLog = EditAlarmLog (curAlarmLog);
		}

		// cmd: resp_initialize:
		else if (command === 'resp_initialize')
		{
			console.log('[global-ws, init] global initialization succeeded.');
			wsManager.store.hooks.setNoConnection (false);
			wsManager.status = 'on';
			wsManager.start();
			
		}

		// cmd: unkown command.
		else if ('internal_id' in resp) {
			controllerOnMessage (wsManager, resp);
		}
	}
}
