import { convert_settings } from '../Constants/settings';
import { RemoveMessageOfController, UpdateHistory, TransformHistoryValue } from '../Utils/utils.js';
import { history_values } from '../Constants/constants';

import _ from 'lodash';

export default function controllerOnMessage (wsManager, resp)
{
	console.log ('controllerOnMessage action', resp);
	const controller_id = resp !== undefined ? resp.internal_id : undefined;
	const cur_controller = wsManager.store.getControllerById (controller_id);
	const controller_name = cur_controller !== undefined ? cur_controller.name : controller_id;
	
	const messages = wsManager.store.messages;
	
	const hooks = wsManager.store.hooks;

	// Parse message and get command.
	if (!('command' in resp)) console.log('[cont-ws] invalid command!');
	let command = resp['command'];
	if (command !== 'update_values') {
		console.log('[cont-ws] got command:', command, resp);
	}
	
	if (command === 'controller_deleted') {
		if (cur_controller.simulation) {
			cur_controller.simulation.stop();
			if (cur_controller.id === wsManager.store.simulation.controller.id)
			{
				wsManager.store.simulation = null;
			}
			
		}
		
		wsManager.store.controllers = wsManager.store.controllers.filter ( function (cont) {
			return cont.id !== controller_id;
		});
		
		sessionStorage.setItem('controllers', JSON.stringify(wsManager.store.controllers));
		
		hooks.enqueueSnackbar(controller_name + ' - deleted', { variant: 'info' });
	}
	else if (command === 'controller_deleted_failed') {
		//wsManager.store.curControllerId = controller_id;
		hooks.setOpenDeleteDialog(true);
	}

	// cmd: message:
	if (command === 'message') {
		const data = resp['data'];
		// Create message, by translating simple string or several strings in array.
		let text = '';
		for (const part of data['message_parts']) text += hooks.t(part);
		// Get other data.
		const type = data['type'] === 'information' ? 'info' : data['type'];
		// Add snackbar
		hooks.enqueueSnackbar(controller_name + ' - ' + text, { variant: type });
		// If not blocked, add to log.
		if (!data['dont_log']) {
			const timestamp = data['timestamp'];
			const newMessage = {
				timestamp: timestamp,
				id: timestamp + text,
				type: type,
				message: text,
				controller: controller_name
			};
			
			messages.messages.push (newMessage);
			messages.unreadMessages = true;
		}
	}

	//cmd: send_all_messages
	else if (command === 'send_all_messages') {
		const data = resp['data'];
		// Remove all messages from this controller
		let currentMessages = RemoveMessageOfController (wsManager.store.messages.messages, controller_name);
		// Add new messages for this controller.
		const newMessages = data['all_messages'];
		for (var i = 0; i < newMessages.length; i++) {
			const message = newMessages[i];
			let text = '';
			for (const part of message['message_parts']) text += hooks.t(part);
			const type = message['type'] === 'information' ? 'info' : message['type'];
			const timestamp = message['timestamp'];
			currentMessages.push({
				timestamp: timestamp,
				id: timestamp + text,
				type: type,
				message: text,
				controller: controller_name
			});
		}
		
		
		messages.messages = currentMessages;
		messages.unreadMessages = currentMessages.length > 0;
	}
	
	//cmd: resp_action
	else if (command === 'resp_action') {
		console.log ('resp action');
		
		const new_info = resp['data']['info'];
		let message =
			'For ' +
			new_info['id'] +
			('value' in new_info ? ' with value ' + new_info['value'] : '') +
			': ';
		let type = '';
		if (resp.data['success'] === true) {
			message += 'success';
			type = 'success';
		} else {
			message += 'failed';
			type = 'error';
		}
		
		hooks.enqueueSnackbar (message, { variant: type });
	}

	// cmd: update_values:
	else if (command === 'update_values') {
		// Update controller last-received:
		cur_controller.connection.last_received = Date.now();

    // Convert timing and replace 0xFFFF values with -1
		const data = convert_settings (resp['data']);
		//const data = resp['data'];
		const timestamp = data['timestamp'];
		
		// Update all values
		cur_controller.settings = _.merge(cur_controller.settings, data);
		cur_controller.infos = _.merge(cur_controller.infos, cur_controller.settings.cur_values);
		delete cur_controller.settings.cur_values;
		
		// If more than three keys, all values are received, and need no longer be requested.
		if (Object.keys(data).length > 4) {
			cur_controller.connection.request_values_full = true;
			console.log ('[cont-ws] recieved more than info: ', Object.keys(data));
		}
		
		// add to history if
		//  a) values is in history,
		//  b) history is complete and
		//  c) matches resolution
		if (cur_controller.history.progress === 100) {
			const elapsed_time = timestamp - cur_controller.history.last_ts;
			if (elapsed_time > cur_controller.history.resolution * 1000) {
				for (const type of history_values) {
					// Get transformed value (0xFFFF=-1, co2=val, other: val/10)
					cur_controller.history[type].push ( TransformHistoryValue (cur_controller.infos[type], type) );
				}
				cur_controller.history['time'].push(timestamp);
				cur_controller.history.last_ts = timestamp;
			}
		}
		
		// update controller-date and time from current values to assure live
    // updating of said values (instead of values remaning 
		if ('time' in data['cur_values'] && 'timing' in cur_controller.settings) {
			cur_controller.settings.timing.controller_time = data['cur_values']['time'];
		}
		if ('date' in data['cur_values'] && 'timing' in cur_controller.settings) {
			const date = data['cur_values']['date'].split('.');
			cur_controller.settings.timing.controller_date =
				date[2] + '-' + date[1] + '-' + date[0];
		}
		
		// TODO: check if wee need this
		// handle memo names:
		if ('multi_out' in data && 'memo_names' in data['multi_out']) {
			for (const memo_name of data['multi_out']['memo_names'])
				cur_controller.settings['multi_out'][memo_name['id']] = memo_name['name'];
		}
	}
	// cmd: send_info_graphs
	else if (command === 'send_info_graphs') {
		const hgraph = resp['data']['humidity_graph'];
		cur_controller.infos['humidity_graph'] = hgraph.map((val) => TransformHistoryValue (val, 'humidity') );
		const tgraph = resp['data']['temp_graph'];
		cur_controller.infos['temp_graph'] = tgraph.map((val) => TransformHistoryValue (val, 'temp'));
	}
	
	// cmd: send_recent_history:
	else if (
		cur_controller.history.progress < 100 &&
		['send_recent_history', 'send_daily_history', 'send_history'].indexOf(command) !== -1
	) {
		// Update history-infos
		//cur_controller.history = UpdateHistory (cur_controller.history, resp['data']);
		UpdateHistory (cur_controller.history, resp['data']);
	}
}
