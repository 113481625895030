import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import { ApplyDeleteSelect } from '../FormWidgets/Select.js';

export function GetSavedSettings(settings_id) {
  try {
    return JSON.parse(localStorage.getItem(settings_id));
  } catch (e) {
    return undefined;
  }
}

export function LoadSettingsDialog({ open, handleClose, controls, controller }) {
  let { t } = useTranslation();

  const [settings, setSettings] = React.useState({ apply: 1, remember: false });
  const handleInput = (name, event, value) => {
    const cur_settings = { ...settings };
    cur_settings[name] = value;
    setSettings({ ...cur_settings });
  };

  const apply = (event) => {
    if (settings.remember) localStorage.setItem('auto_load', settings.apply);
    if (settings.apply) {

      controls.restoreTempSettings();
    }
    else
    {
		controls.resetSettings();
	}

    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('auto_saved_settings')}</DialogTitle>
      <DialogContent>
        <Typography> {t('auto_save_apply')}</Typography>
        <ApplyDeleteSelect
          value={settings.apply}
          onChange={(event, value) => handleInput('apply', event, event.target.value)}
        />
        <Typography></Typography>
        <Checkbox
          checked={settings.remember}
          onChange={(event, value) => handleInput('remember', event, value)}
        />
        {t('auto_save_remember')}
      </DialogContent>
      <DialogActions>
        <Button onClick={apply} color="primary">
          {t('auto_save_ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function SavedDifferFromController(auto_saved_settings_id, controller_values) {
  const saved_values = GetSavedSettings(auto_saved_settings_id);
  if (saved_values === undefined || saved_values === null) return false;
  for (var key in controller_values) {
    if (saved_values[key] !== controller_values[key]) {
      console.log(
        '[saved-differ] Values differ for',
        key,
        saved_values[key],
        controller_values[key]
      );
      return true;
    }
  }
  return false;
}
