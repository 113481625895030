import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';

import { ShowSettingOnOff } from '../FormWidgets/ShowSliderSetting';
import { OffOnSettings } from '../FormWidgets/Select.js';
import Desc from '../FormWidgets/Description';
import { alarm_threshold } from '../Constants/constants';
import { LoadSettingsDialog } from '../Utils/load_settings';
import { SettingGroup, SettingGroupGrid, ValueBox } from '../FormWidgets/SettingGroup';

import useStateManager from '../StateManager.js';
import {
  settingControls,
  useSavedSettings
} from './settingFunctions';


import { SettingActions } from '../components';

export default function AlarmSettings({ contType, ...other }) {
  let { t } = useTranslation();
  let { id, tab_id } = useParams();
  
 const stateManager = useStateManager();
 const controller = stateManager.getControllerById (id);
 const [values, setValues] = useState (controller.settings[tab_id]);
 const controls = settingControls (controller, tab_id, stateManager.saveSettings, values, setValues);
 const { openLoadSettingsDialog, closeLoadSettingsDialog } = useSavedSettings (controller, controls);

  return (
    <React.Fragment>
      <LoadSettingsDialog
        open={openLoadSettingsDialog}
        handleClose={closeLoadSettingsDialog}
        controls={controls}
      />

      <SettingActions
        onReset={controls.resetSettings}
        onSave={controls.saveSettings}
        
      />

      <SettingGroupGrid label={t('alarm_temperature_air_day')}>
        <ShowSettingOnOff
          id="alarm_low_temperature_air_day_setting"
          cur_value={values.alarm_low_temperature_air_day_setting}
          controller_value={controller.settings.alarms.alarm_low_temperature_air_day_setting}
          max={( controller.settings.climate.temp_sp_day / 10 ) - alarm_threshold['temp']}
          controls={controls}
                    type="float"
          switchId="alarm_low_temperature_air_day_enable"
          switchValue={values.alarm_low_temperature_air_day_enable}
                  />
        <ShowSettingOnOff
          id="alarm_over_temperature_air_day_setting"
          cur_value={values.alarm_over_temperature_air_day_setting}
          controller_value={controller.settings.alarms.alarm_over_temperature_air_day_setting}
          min={( controller.settings.climate.temp_sp_day / 10 ) + alarm_threshold['temp']}
          controls={controls}
                    type="float"
          switchId="alarm_over_temperature_air_day_enable"
          switchValue={values.alarm_over_temperature_air_day_enable}
                  />
      </SettingGroupGrid>

      <SettingGroupGrid label={t('alarm_temperature_air_night')}>
        <ShowSettingOnOff
          id="alarm_low_temperature_air_night_setting"
          cur_value={values.alarm_low_temperature_air_night_setting}
          controller_value={controller.settings.alarms.alarm_low_temperature_air_night_setting}
          max={( controller.settings.climate.temp_sp_night / 10 ) - alarm_threshold['temp']}
          controls={controls}
                    type="float"
          switchValue={values.alarm_low_temperature_air_night_enable}
          switchId="alarm_low_temperature_air_night_enable"
        />
        <ShowSettingOnOff
          id="alarm_over_temperature_air_night_setting"
          cur_value={values.alarm_over_temperature_air_night_setting}
          controller_value={controller.settings.alarms.alarm_over_temperature_air_night_setting}
          min={( controller.settings.climate.temp_sp_night / 10 ) + alarm_threshold['temp']}
          controls={controls}
                    type="float"
          switchValue={values.alarm_over_temperature_air_night_enable}
          switchId="alarm_over_temperature_air_night_enable"
        />
      </SettingGroupGrid>

      <SettingGroupGrid label={t('alarm_temperature_co2')}>
        <ShowSettingOnOff
          id="alarm_low_temperature_air_co2_setting"
          cur_value={values.alarm_low_temperature_air_co2_setting}
          controller_value={controller.settings.alarms.alarm_low_temperature_air_co2_setting}
          max={( controller.settings.co2.temp_sp_co2 / 10 ) - alarm_threshold['temp']}
          controls={controls}
                    type="float"
          switchValue={values.alarm_low_temperature_air_co2_enable}
          switchId="alarm_low_temperature_air_co2_enable"
        />
        <ShowSettingOnOff
          id="alarm_over_temperature_air_co2_setting"
          cur_value={values.alarm_over_temperature_air_co2_setting}
          controller_value={controller.settings.alarms.alarm_over_temperature_air_co2_setting}
          min={( controller.settings.co2.temp_sp_co2 / 10 ) + alarm_threshold['temp']}
          controls={controls}
                    type="float"
          switchValue={values.alarm_over_temperature_air_co2_enable}
          switchId="alarm_over_temperature_air_co2_enable"
        />
      </SettingGroupGrid>

      <SettingGroupGrid label={t('alarm_co2_concentration')}>
        <ShowSettingOnOff
          id="alarm_low_co2_concentration_setting"
          cur_value={values.alarm_low_co2_concentration_setting}
          controller_value={controller.settings.alarms.alarm_low_co2_concentration_setting}
          max={controller.settings.co2.co2_sp - alarm_threshold['co2']}
          controls={controls}
                    switchValue={values.alarm_low_co2_concentration_enable}
          switchId="alarm_low_co2_concentration_enable"
        />
        <ShowSettingOnOff
          id="alarm_over_co2_concentration_setting"
          cur_value={values.alarm_over_co2_concentration_setting}
          controller_value={controller.settings.alarms.alarm_over_co2_concentration_setting}
          min={controller.settings.co2.co2_sp + alarm_threshold['co2']}
          controls={controls}
                    switchValue={values.alarm_over_co2_concentration_enable}
          switchId="alarm_over_co2_concentration_enable"
        />
      </SettingGroupGrid>

      <SettingGroupGrid label={t('alarm_rh_day')}>
        <ShowSettingOnOff
          id="alarm_low_rh_day_setting"
          cur_value={values.alarm_low_rh_day_setting}
          controller_value={controller.settings.alarms.alarm_low_rh_day_setting}
          max={controller.settings.climate.humidity_sp_day - alarm_threshold['rh']}
          controls={controls}
                    switchValue={values.alarm_low_rh_day_enable}
          switchId="alarm_low_rh_day_enable"
        />
        <ShowSettingOnOff
          id="alarm_over_rh_day_setting"
          cur_value={values.alarm_over_rh_day_setting}
          controller_value={controller.settings.alarms.alarm_over_rh_day_setting}
          min={controller.settings.climate.humidity_sp_day + alarm_threshold['rh']}
          controls={controls}
                    switchValue={values.alarm_over_rh_day_enable}
          switchId="alarm_over_rh_day_enable"
        />
      </SettingGroupGrid>

      <SettingGroupGrid label={t('alarm_rh_night')}>
        <ShowSettingOnOff
          id="alarm_low_rh_night_setting"
          cur_value={values.alarm_low_rh_night_setting}
          controller_value={controller.settings.alarms.alarm_low_rh_night_setting}
          max={controller.settings.climate.humidity_sp_night - alarm_threshold['rh']}
          controls={controls}
                    switchValue={values.alarm_low_rh_night_enable}
          switchId="alarm_low_rh_night_enable"
        />
        <ShowSettingOnOff
          id="alarm_over_rh_night_setting"
          cur_value={values.alarm_over_rh_night_setting}
          controller_value={controller.settings.alarms.alarm_over_rh_night_setting}
          min={controller.settings.climate.humidity_sp_night + alarm_threshold['rh']}
          controls={controls}
                    switchValue={values.alarm_over_rh_night_enable}
          switchId="alarm_over_rh_night_enable"
        />
      </SettingGroupGrid>

      {contType === 5055 && (
        <SettingGroup label={t('alarm_vpd_day_hl')}>
          <ShowSettingOnOff
            id="alarm_low_vpd_setting"
            cur_value={values.alarm_low_vpd_setting}
            controller_value={controller.settings.alarms.alarm_low_vpd_setting}
            max={ ( controller.settings.climate.vpd_sp / 10 ) - alarm_threshold['vpd']}
            controls={controls}
                        type="float"
            switchValue={values.alarm_low_vpd_enable}
            switchId="alarm_low_vpd_enable"
          />
          <ShowSettingOnOff
            id="alarm_over_vpd_setting"
            cur_value={values.alarm_over_vpd_setting}
            controller_value={controller.settings.alarms.alarm_over_vpd_setting}
            min={ ( controller.settings.climate.vpd_sp / 10 ) + alarm_threshold['vpd']}
            controls={controls}
                        type="float"
            switchValue={values.alarm_over_vpd_enable}
            switchId="alarm_over_vpd_enable"
          />
        </SettingGroup>
      )}
      {contType === 5055 && (
        <SettingGroup label={t('alarm_vpd_night_hl')}>
          <ShowSettingOnOff
            id="alarm_low_vpd_night_setting"
            cur_value={values.alarm_low_vpd_night_setting}
            controller_value={controller.settings.alarms.alarm_low_vpd_night_setting}
            max={( controller.settings.climate.vpd_sp_night / 10 ) - alarm_threshold['vpd']}
            controls={controls}
                        type="float"
            switchValue={values.alarm_low_vpd_night_enable}
            switchId="alarm_low_vpd_night_enable"
          />
          <ShowSettingOnOff
            id="alarm_over_vpd_night_setting"
            cur_value={values.alarm_over_vpd_night_setting}
            controller_value={controller.settings.alarms.alarm_over_vpd_night_setting}
            min={( controller.settings.climate.vpd_sp_night / 10 ) + alarm_threshold['vpd']}
            controls={controls}
                        type="float"
            switchValue={values.alarm_over_vpd_night_enable}
            switchId="alarm_over_vpd_night_enable"
          />
        </SettingGroup>
      )}

      <SettingGroup label={t('sensor_errors')}>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="alarm_sensor_error_rht_enable" name="Sensor Error RHT" />
          </Typography>
          <OffOnSettings
            value={values.alarm_sensor_error_rht_enable}
            name="alarm_sensor_error_rht_enable"
              
            onChange={(event, value) => controls.setValue ('alarm_sensor_error_rht_enable', event.target.value)}
          />
        </ValueBox>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="alarm_sensor_error_co2_enable" name="Sensor Error CO2 " />
          </Typography>
          <OffOnSettings
            value={values.alarm_sensor_error_co2_enable}
            name="alarm_sensor_error_co2_enable"
            onChange={(event, value) => controls.setValue ('alarm_sensor_error_co2_enable', event.target.value)}
          />
        </ValueBox>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="alarm_sensor_error_pressure_enable" name="Sensor Error Pressure" />
          </Typography>
          <OffOnSettings
            value={values.alarm_sensor_error_pressure_enable}
            name="alarm_sensor_error_pressure_enable"
            onChange={(event, value) => controls.setValue ('alarm_sensor_error_pressure_enable', event.target.value)}
          />
        </ValueBox>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="alarm_sensor_error_heating_mat_enable" name="Sensor Error Heating Mat" />
          </Typography>
          <OffOnSettings
            value={values.alarm_sensor_error_heating_mat_enable}
            name="alarm_sensor_error_heating_mat_enable"
            onChange={(event, value) => controls.setValue ('alarm_sensor_error_rht_enable', event.target.value)}
          />
        </ValueBox>
        {contType === 5055 && (
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="alarm_sensor_error_leaf_enable" name="Sensor Error Leaf" />
            </Typography>
            <OffOnSettings
              value={values.alarm_sensor_error_leaf_enable}
              name="alarm_sensor_error_leaf_enable"
              onChange={(event, value) => controls.setValue ('alarm_sensor_error_leaf_enable', event.target.value)}
            />
          </ValueBox>
        )}
        {contType === 5055 && (
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="alarm_sensor_error_intake_enable" name="Sensor Error Leaf" />
            </Typography>
            <OffOnSettings
              value={values.alarm_sensor_error_intake_enable}
              name="alarm_sensor_error_intake_enable"
              onChange={(event, value) => controls.setValue ('alarm_sensor_error_intake_enable', event.target.value)}
            />
          </ValueBox>
        )}
      </SettingGroup>

      {contType === 5055 && (
        <SettingGroup label={t('alarm_additional_errors')}>
          <ShowSettingOnOff
            id="expected_power_extender_out_cnt"
            cur_value={values.expected_power_extender_out_cnt}
            controller_value={controller.settings.alarms.expected_power_extender_out_cnt}
            controls={controls}
                        switchValue={values.alarm_error_power_extender_enable}
            switchId="alarm_error_power_extender_enable"
          />
        </SettingGroup>
      )}

      <SettingActions
        onReset={controls.resetSettings}
        onSave={controls.saveSettings}
        
      />
    </React.Fragment>
  );
}
