import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import { useTranslation } from 'react-i18next';

import { DatePicker, MyTimePicker } from '../FormWidgets/DatePicker.js';
import Desc from '../FormWidgets/Description';
import { SettingGroup, ValueBox } from '../FormWidgets/SettingGroup';
import { EventGapSelect } from '../FormWidgets/Select.js';
import { addZ } from '../Utils/utils.js';
import { LoadSettingsDialog } from '../Utils/load_settings';
import { re_convert_timing } from '../Constants/settings.js';
import { SettingActions } from '../components/index.js';

import {
  settingControls,
  useSavedSettings,
  writeTempSettings,
} from './settingFunctions';

import useStateManager from '../StateManager.js';

export default function TimingSettings({ contType, setPhase, ...other }) {
  let { t } = useTranslation();
  let { id, tab_id } = useParams();
  
   const stateManager = useStateManager();
  const controller = stateManager.getControllerById (id);
  const [values, setValues] = useState (controller.settings[tab_id]);
  const controls = settingControls (controller, tab_id, stateManager.saveSettings, values, setValues);
  const { openLoadSettingsDialog, closeLoadSettingsDialog } = useSavedSettings (controller, controls);
  
  const [cont_datetime_changed, setContDateTimeChanged] = React.useState({
    date: false,
    time: false
  });

  controls.saveSettings = () => {
	  let timingSettings = {...values};
    timingSettings._datetime_changes = { ...cont_datetime_changed }
    const transform = (settings) => {
      // Re-convert timing to have seperate values for hour, minute, second...
      let reconverted_timing_settings = re_convert_timing(settings);
      // Send 0xFF.. for controller date/ time if not changed.
      if (!settings._datetime_changes.date) {
        reconverted_timing_settings['time_cont_year'] = 0xffff;
        reconverted_timing_settings['time_cont_month'] = 0xff;
        reconverted_timing_settings['time_cont_day'] = 0xff;
      }
      if (!settings._datetime_changes.time) {
        reconverted_timing_settings['time_cont_hour'] = 0xffff;
        reconverted_timing_settings['time_cont_min'] = 0xff;
        reconverted_timing_settings['time_cont_sec'] = 0xff;
      }
      return reconverted_timing_settings;
    }

		// Get settings
		stateManager.saveSettings (controller, { ...timingSettings }, tab_id, transform);
    // Also detele temp settings and write new temp 
    delete controller.temp_settings[tab_id];
    writeTempSettings (controller);
  };


  const handleInput = (name, value, type) => {
    let timingSettings = { ...values };
    if (type === 'seconds')
      value =
        addZ(value.getHours()) + ':' + addZ(value.getMinutes()) + ':' + addZ(value.getSeconds());
    else if (type !== 'date' && type !== 'int')
      value = addZ(value.getHours()) + ':' + addZ(value.getMinutes());
    timingSettings[name] = value;
    if (name === 'controller_date')
      setContDateTimeChanged({ ...cont_datetime_changed, date: true });
    else if (name === 'controller_time')
      setContDateTimeChanged({ ...cont_datetime_changed, time: true });
    
    setValues({ ...timingSettings});
    controls.saveTempSettings (name, value);
  };

  return (
    <React.Fragment>
      <LoadSettingsDialog
        open={openLoadSettingsDialog}
        handleClose={closeLoadSettingsDialog}
        controls={controls}
      />

      <SettingActions
        onReset={controls.resetSettings}
        onSave={controls.saveSettings}
        
      />

      <SettingGroup label={t('controller_time')}>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="controller_date" name="Controller Date" />
          </Typography>
          <DatePicker
            value={values.controller_date}
            name="controller_date"
            
            onChange={(event, value) => handleInput('controller_date', value, 'date')}
          />
        </ValueBox>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="controller_time" name="Controller Time" />
          </Typography>
          <MyTimePicker
            value={values.controller_time}
            seconds={true}
            name="controller_time"
            id="controller_time"
            
            onChange={(value) => handleInput('controller_time', value, 'seconds')}
          />
        </ValueBox>
      </SettingGroup>

      <SettingGroup label={t('start_end_time')}>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="start_day" name="Start Day at" />
          </Typography>
          <MyTimePicker
            value={values.start_day}
            seconds={false}
            name="start_day"
            
            onChange={(value) => handleInput('start_day', value, '')}
          />
        </ValueBox>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="end_day" name="End Day at" />
          </Typography>
          <MyTimePicker
            value={values.end_day}
            seconds={false}
            name="end_day"
            
            onChange={(value) => handleInput('end_day', value, '')}
          />
        </ValueBox>
      </SettingGroup>

      <SettingGroup label={t('interval_day')}>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="interval_on_day" name="Interval On (Day)" />
          </Typography>
          <MyTimePicker
            value={values.interval_on_day}
            seconds={true}
            name="interval_on_day"
            
            onChange={(value) => handleInput('interval_on_day', value, 'seconds')}
          />
        </ValueBox>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="interval_off_day" name="Interval Off (day)" />
          </Typography>
          <MyTimePicker
            value={values.interval_off_day}
            seconds={true}
            name="interval_off_day"
            
            onChange={(value) => handleInput('interval_off_day', value, 'seconds')}
          />
        </ValueBox>
      </SettingGroup>

      <SettingGroup label={t('interval_night')}>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="interval_on_night" name="Interval On (Night)" />
          </Typography>
          <MyTimePicker
            value={values.interval_on_night}
            seconds={true}
            name="interval_on_night"
            
            onChange={(value) => handleInput('interval_on_night', value, 'seconds')}
          />
        </ValueBox>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="interval_off_night" name="Interval Off (night)" />
          </Typography>
          <MyTimePicker
            value={values.interval_off_night}
            seconds={true}
            name="interval_off_night"
            
            onChange={(value) => handleInput('interval_off_night', value, 'seconds')}
          />
        </ValueBox>
      </SettingGroup>

      {contType === 5055 && (
        <SettingGroup label={t('interval_2_day')}>
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="time_interval_2_on_day" show="interval_2_on_day" />
            </Typography>
            <MyTimePicker
              value={values.time_interval_2_on_day}
              seconds={true}
              name="interval_2_on_day"
              
              onChange={(value) => handleInput('time_interval_2_on_day', value, 'seconds')}
            />
          </ValueBox>
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="time_interval_2_off_day" show="interval_2_off_day" />
            </Typography>
            <MyTimePicker
              value={values.time_interval_2_off_day}
              seconds={true}
              name="time_interval_2_off_day"
              
              onChange={(value) => handleInput('time_interval_2_off_day', value, 'seconds')}
            />
          </ValueBox>
        </SettingGroup>
      )}

      {contType === 5055 && (
        <SettingGroup label={t('interval_2_night')}>
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="time_interval_2_on_night" show="interval_2_on_night" />
            </Typography>
            <MyTimePicker
              value={values.time_interval_2_on_night}
              seconds={true}
              name="time_interval_2_on_night"
              
              onChange={(value) => handleInput('time_interval_2_on_night', value, 'seconds')}
            />
          </ValueBox>
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="time_interval_2_off_night" show="interval_2_off_night" />
            </Typography>
            <MyTimePicker
              value={values.time_interval_2_off_night}
              seconds={true}
              name="time_interval_2_off_night"
              
              onChange={(value) => handleInput('time_interval_2_off_night', value, 'seconds')}
            />
          </ValueBox>
        </SettingGroup>
      )}

      <SettingGroup label={t('event_1')}>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="event1_start" show="event_start" />
          </Typography>
          <MyTimePicker
            value={values.event1_start}
            seconds={false}
            name="event1_start"
            
            onChange={(value) => handleInput('event1_start', value, '')}
          />
        </ValueBox>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="event1_duration" show="event_duration" />
          </Typography>
          <MyTimePicker
            value={values.event1_duration}
            seconds={true}
            name="event1_duration"
            
            onChange={(value) => handleInput('event1_duration', value, 'seconds')}
          />
        </ValueBox>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="time_event1_exec_gap" show="event_exec_gap" />
          </Typography>
          <EventGapSelect
            value={values.time_event1_exec_gap}
            
            onChange={(event, value) =>
              handleInput('time_event1_exec_gap', event.target.value, 'int')
            }
          />
        </ValueBox>
      </SettingGroup>

      <SettingGroup label={t('event_2')}>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="event2_start" show="event_start" />
          </Typography>
          <MyTimePicker
            value={values.event2_start}
            seconds={false}
            name="event2_start"
            
            onChange={(value) => handleInput('event2_start', value)}
          />
        </ValueBox>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="event2_duration" show="event_duration" />
          </Typography>
          <MyTimePicker
            value={values.event2_duration}
            seconds={true}
            name="event2_duration"
            
            onChange={(value) => handleInput('event2_duration', value, 'seconds')}
          />
        </ValueBox>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="time_event2_exec_gap" show="event_exec_gap" />
          </Typography>
          <EventGapSelect
            value={values.time_event2_exec_gap}
            
            onChange={(event, value) =>
              handleInput('time_event2_exec_gap', event.target.value, 'int')
            }
          />
        </ValueBox>
      </SettingGroup>

      <SettingGroup label={t('event_3')}>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="event3_start" show="event_start" />
          </Typography>
          <MyTimePicker
            value={values.event3_start}
            seconds={false}
            name="event3_start"
            
            onChange={(value) => handleInput('event3_start', value, '')}
          />
        </ValueBox>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="event3_duration" show="event_duration" />
          </Typography>
          <MyTimePicker
            value={values.event3_duration}
            seconds={true}
            name="event3_duration"
            
            onChange={(value) => handleInput('event3_duration', value, 'seconds')}
          />
        </ValueBox>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="time_event3_exec_gap" show="event_exec_gap" />
          </Typography>
          <EventGapSelect
            value={values.time_event3_exec_gap}
            
            onChange={(event, value) =>
              handleInput('time_event3_exec_gap', event.target.value, 'int')
            }
          />
        </ValueBox>
      </SettingGroup>

      <SettingGroup label={t('event_4')}>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="event4_start" show="event_start" />
          </Typography>
          <MyTimePicker
            value={values.event4_start}
            seconds={false}
            name="event4_start"
            
            onChange={(value) => handleInput('event4_start', value, '')}
          />
        </ValueBox>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="event4_duration" show="event_duration" />
          </Typography>
          <MyTimePicker
            value={values.event4_duration}
            seconds={true}
            name="event4_duration"
            
            onChange={(value) => handleInput('event4_duration', value, 'seconds')}
          />
        </ValueBox>
        <ValueBox>
          <Typography gutterBottom>
            <Desc id="time_event4_exec_gap" show="event_exec_gap" />
          </Typography>
          <EventGapSelect
            value={values.time_event4_exec_gap}
            
            onChange={(event, value) =>
              handleInput('time_event4_exec_gap', event.target.value, 'int')
            }
          />
        </ValueBox>
      </SettingGroup>

      {contType === 5055 && (
        <SettingGroup label={t('event_5')}>
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="event5_start" show="event_start" />
            </Typography>
            <MyTimePicker
              value={values.event5_start}
              seconds={false}
              name="event5_start"
              
              onChange={(value) => handleInput('event5_start', value, '')}
            />
          </ValueBox>
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="event5_duration" show="event_duration" />
            </Typography>
            <MyTimePicker
              value={values.event5_duration}
              seconds={true}
              name="event5_duration"
              
              onChange={(value) => handleInput('event5_duration', value, 'seconds')}
            />
          </ValueBox>
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="time_event5_exec_gap" show="event_exec_gap" />
            </Typography>
            <EventGapSelect
              value={values.time_event5_exec_gap}
              
              onChange={(event, value) =>
                handleInput('time_event5_exec_gap', event.target.value, 'int')
              }
            />
          </ValueBox>
        </SettingGroup>
      )}

      {contType === 5055 && (
        <SettingGroup label={t('event_6')}>
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="event6_start" show="event_start" />
            </Typography>
            <MyTimePicker
              value={values.event6_start}
              seconds={false}
              name="event6_start"
              
              onChange={(value) => handleInput('event6_start', value, '')}
            />
          </ValueBox>
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="event6_duration" show="event_duration" />
            </Typography>
            <MyTimePicker
              value={values.event6_duration}
              seconds={true}
              name="event6_duration"
              
              onChange={(value) => handleInput('event1_duration', value, 'seconds')}
            />
          </ValueBox>
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="time_event6_exec_gap" show="event_exec_gap" />
            </Typography>
            <EventGapSelect
              value={values.time_event6_exec_gap}
              
              onChange={(event, value) =>
                handleInput('time_event6_exec_gap', event.target.value, 'int')
              }
            />
          </ValueBox>
        </SettingGroup>
      )}

      {contType === 5055 && (
        <SettingGroup label={t('event_7')}>
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="event7_start" show="event_start" />
            </Typography>
            <MyTimePicker
              value={values.event7_start}
              seconds={false}
              name="event7_start"
              
              onChange={(value) => handleInput('event7_start', value, '')}
            />
          </ValueBox>
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="event7_duration" show="event_duration" />
            </Typography>
            <MyTimePicker
              value={values.event7_duration}
              seconds={true}
              name="event7_duration"
              
              onChange={(value) => handleInput('event7_duration', value, 'seconds')}
            />
          </ValueBox>
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="time_event7_exec_gap" show="event_exec_gap" />
            </Typography>
            <EventGapSelect
              value={values.time_event7_exec_gap}
              
              onChange={(event, value) =>
                handleInput('time_event7_exec_gap', event.target.value, 'int')
              }
            />
          </ValueBox>
        </SettingGroup>
      )}

      {contType === 5055 && (
        <SettingGroup label={t('event_8')}>
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="event8_start" show="event_start" />
            </Typography>
            <MyTimePicker
              value={values.event8_start}
              seconds={false}
              name="event8_start"
              
              onChange={(value) => handleInput('event8_start', value, '')}
            />
          </ValueBox>
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="event8_duration" show="event_duration" />
            </Typography>
            <MyTimePicker
              value={values.event8_duration}
              seconds={true}
              name="event8_duration"
              
              onChange={(value) => handleInput('event8_duration', value, 'seconds')}
            />
          </ValueBox>
          <ValueBox>
            <Typography gutterBottom>
              <Desc id="time_event8_exec_gap" show="event_exec_gap" />
            </Typography>
            <EventGapSelect
              value={values.time_event8_exec_gap}
              
              onChange={(event, value) =>
                handleInput('time_event8_exec_gap', event.target.value, 'int')
              }
            />
          </ValueBox>
        </SettingGroup>
      )}

      <SettingActions
        onReset={controls.resetSettings}
        onSave={controls.saveSettings}
        
      />
    </React.Fragment>
  );
}
